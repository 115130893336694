import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Creators } from 'store/ducks/athlete';
import { useTranslation } from 'react-i18next';
import { Card, Spinner, Menu, IconButton, Dialog, SelectionButton, CalendarMenu } from 'components/template';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import styles from './Evaluations.module.scss';
import EvaluationsTable from 'components/EvaluationsTable/EvaluationsTable';
import IconButtonText from 'components/template/IconButtonText/IconButtonText';
import moment from 'moment';
import { FaTrashAlt } from 'react-icons/fa';
import AthleteCharts from './athleteCharts/athleteCharts';

export default function Evaluations({ athleteId, setEvaluationCount }) {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const evaluations = useSelector(state => state.athlete.evaluationsList);
  const evaluationsDashboard = useSelector(state => state.athlete.evaluationsDashboard);

  const initialFilters = {
    analyst: [],
    club: [],
    period_after: null,
    period_before: null,
    priority: [],
    mode: [],
    recommendation: [],
    viewList: 'true',
  };

  const [analysts, setAnalysts] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [modes, setModes] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [checkedEvaluations, setCheckedEvaluations] = useState([]);

  const handleEvaluationsDelete = () => {
    setIsOpenDialog(false);
    dispatch(
      Creators.deleteEvaluationsLists(
        checkedEvaluations.map(evaluation => evaluation.id),
        athleteId
      )
    );
    setEvaluationCount(checkedEvaluations.length);
    setCheckedEvaluations([]);
  };

  useEffect(() => {
    if (filters.viewList === 'true' && athleteId) {
      dispatch(Creators.getEvaluationsList(athleteId));
    } else if (athleteId) {
      dispatch(Creators.getEvaluationsList(athleteId));
      dispatch(Creators.getEvaluationsDashboard(athleteId, filters));
    }
    return () => {
      dispatch(Creators.resetEvaluationsList());
    };
  }, [dispatch, athleteId, filters]);

  useEffect(() => {
    if (!evaluations.data || evaluations.data.length === 0) return;
    const uniqueNames = new Set();
    const uniqueClubs = new Set();
    const uniquePriorities = new Set();
    const uniqueModes = new Set();
    const uniqueRecommendations = new Set();

    evaluations.data.forEach(evaluation => {
      if (evaluation.author === undefined) {
        return;
      }
      uniqueNames.add(JSON.stringify(evaluation.author));
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.team.name === undefined) {
        return;
      }
      uniqueClubs.add(evaluation.team.name);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.priority === undefined) {
        return;
      }
      uniquePriorities.add(evaluation.priority);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.mode === undefined) {
        return;
      }
      uniqueModes.add(evaluation.mode);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.recommendation === undefined) {
        return;
      }
      uniqueRecommendations.add(evaluation.recommendation);
    });

    let analystsArray = [];
    uniqueNames.forEach(author => analystsArray.push(JSON.parse(author)));

    setAnalysts(Array.from(analystsArray));
    setClubs(Array.from(uniqueClubs));
    setPriorities(Array.from(uniquePriorities));
    setModes(Array.from(uniqueModes));
    setRecommendations(Array.from(uniqueRecommendations));
  }, [evaluations]);

  const filteredEvaluations = evaluations.data.filter(evaluation => {
    const analystFilter = filters['analyst'];
    const clubFilter = filters['club'];
    const priorityFilter = filters['priority'];
    const modeFilter = filters['mode'];
    const recommendationFilter = filters['recommendation'];
    const periodBefore = filters['period_before'] ? new Date(filters['period_before']) : null;
    const periodAfter = filters['period_after'] ? new Date(filters['period_after']) : null;
    const evaluationDate = new Date(evaluation.date);

    const isWithinPeriod =
      (!periodAfter || evaluationDate >= periodAfter) && (!periodBefore || evaluationDate <= periodBefore);

    if (
      (analystFilter.length === 0 || analystFilter.includes(evaluation.author.id)) &&
      (clubFilter.length === 0 || clubFilter.includes(evaluation.team.name)) &&
      isWithinPeriod &&
      (priorityFilter.length === 0 || priorityFilter.includes(evaluation.priority)) &&
      (modeFilter.length === 0 || modeFilter.includes(evaluation.mode)) &&
      (recommendationFilter.length === 0 || recommendationFilter.includes(evaluation.recommendation))
    ) {
      return true;
    }

    return false;
  });

  let content = '';

  if (evaluations.error) {
    content = (
      <Card bordered softShadow className={styles.evaluations}>
        <span>{t(evaluations.error)}</span>;/
      </Card>
    );
  } else if (evaluations.data) {
    content = (
      <div className={styles.evaluations}>
        <div className={styles.header_container}>
          <span className={styles.header}>{t('field.re_ava1')}</span>
          <div style={{ display: 'flex' }}>
            <span className={styles.sub_header}>{t('field.re_ava2')}</span>
            <IconButtonText
              onClick={() => history.push(`${location.pathname}/nova`)}
              color="lightPurple1"
              text={t('field.add_ava')}
              icon={<BsFillPlusCircleFill />}
              style={{ marginLeft: 'auto' }}
            />
          </div>
        </div>

        <div className={styles.filter_container}>
          {filters.viewList === 'true' && (
            <IconButton
              className={styles.deleteButton}
              onClick={() => {
                setIsOpenDialog(true);
              }}
              icon={<FaTrashAlt />}
              text={t('buttons.excluir')}
              disabled={checkedEvaluations.length === 0}
            />
          )}
          <Menu
            className={styles.menu}
            allowEmpty
            rounded
            title={t('charts.byAnalist')}
            placeholder={t('charts.byAnalist')}
            options={analysts.map(item => ({
              label: item.name !== ' ' ? item.name : item.email,
              value: item.id,
            }))}
            value={filters['analyst']}
            setValue={val => {
              setFilters({ ...filters, analyst: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, analyst: [] });
            }}
          />
          <CalendarMenu
            className={styles.menu}
            label={t('charts.filterByPeriod')}
            minDatePlaceholder={filters.period_after}
            maxDatePlaceholder={filters.period_before}
            minDateValue={filters.period_after}
            maxDateValue={filters.period_before}
            setMinValue={val => {
              const [year, month, day] = val.split('-').map(part => parseInt(part, 10));
              const newDate = new Date(year, month - 1, day, 0, 0, 0);
              const isoDate = newDate.toISOString();
              setFilters({ ...filters, period_after: isoDate });
            }}
            setMaxValue={val => {
              const newDate = new Date(val);
              newDate.setUTCHours(23, 59, 59, 999);
              setFilters({ ...filters, period_before: newDate.toISOString() });
            }}
            onClear={() => setFilters({ ...filters, period_after: null, period_before: null })}
          />
          <Menu
            className={styles.menu}
            title={t('charts.filterByTargeting')}
            placeholder={t('charts.filterByTargeting')}
            rounded
            options={recommendations.map(item => ({
              label: item !== '' ? t(`config.${item}`) : t('config.undefined'),
              value: item,
            }))}
            value={filters['recommendation'][0] === '' ? '' : filters['recommendation']}
            setValue={val => {
              setFilters({ ...filters, recommendation: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, recommendation: [] });
            }}
            allowEmpty
          />
          <Menu
            className={styles.menu}
            title={t('filters.byPriority')}
            placeholder={t('filters.byPriority')}
            rounded
            options={priorities.map(item => ({
              label: item !== '' ? t(`config.${item}`) : t('config.undefined'),
              value: item,
            }))}
            value={filters['priority'][0] === '' ? '' : filters['priority']}
            setValue={val => {
              setFilters({ ...filters, priority: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, priority: [] });
            }}
            allowEmpty
          />
          {filters.viewList === 'true' && (
            <Menu
              className={styles.menu}
              title={t('charts.byModel')}
              placeholder={t('charts.byModel')}
              rounded
              options={modes.map(item => ({
                label: t(`avaliationModel.${item}`),
                value: item,
              }))}
              value={filters['mode']}
              setValue={val => {
                setFilters({ ...filters, mode: [val] });
              }}
              onClear={() => {
                setFilters({ ...filters, mode: [] });
              }}
              allowEmpty
            />
          )}
          <Menu
            className={styles.menu}
            allowEmpty
            rounded
            title={t('charts.byMomentClubShort')}
            placeholder={t('charts.byMomentClubShort')}
            options={clubs.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['club']}
            setValue={val => {
              setFilters({ ...filters, club: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, club: [] });
            }}
          />
          <div className={styles.fixEnd}>
            <SelectionButton
              items={[
                { label: t('myClub.negotiations.list'), value: 'true' },
                { label: t('athleteDashboard.chart'), value: 'false' },
              ]}
              selected={filters['viewList']}
              onClick={val => setFilters({ ...filters, viewList: val })}
            />
          </div>
        </div>
        {evaluations.loading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : filters.viewList === 'true' ? (
          <EvaluationsTable
            evaluations={!filteredEvaluations || evaluations?.data?.length === 0 ? [] : filteredEvaluations}
            checkedEvaluations={checkedEvaluations}
            setCheckedEvaluations={setCheckedEvaluations}
          />
        ) : (
          <AthleteCharts
            evaluations={evaluationsDashboard?.data}
            loading={evaluationsDashboard?.loading}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {content}
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        mainTitle={t('organi.text8')}
        btnLeft={t('dialog_content.cancel')}
        btnRight={t('organi.text9')}
        btnLeftClick={() => setIsOpenDialog(false)}
        btnRightClick={() => handleEvaluationsDelete()}
      >
        {t('organi.text10')}
      </Dialog>
    </>
  );
}

Evaluations.propTypes = {
  athleteId: PropTypes.string.isRequired,
};
