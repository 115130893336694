import React from 'react';
import Datepicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import styles from './Calendar.module.scss';
import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { getI18n } from 'react-i18next';

const Calendar = ({ value, setValue, minDate, maxDate, placeholder, style, isDisabled }) => {
  const valueDate = moment(value);
  const valueIsValid = valueDate.isValid();
  const mminDate = minDate ? moment(minDate) : null;
  const mmaxDate = maxDate ? moment(maxDate) : null;

  const langInfo = getI18n();
  registerLocale('br', ptBR);
  registerLocale('en', enUS);
  registerLocale('esp', es);

  return (
    <Datepicker
      locale={langInfo.language}
      placeholderText={placeholder}
      calendarClassName={styles.calendar}
      selected={valueIsValid ? valueDate.toDate() : null}
      onChange={date => setValue(date ? moment(date).format('YYYY-MM-DD') : '')}
      minDate={mminDate?.isValid() ? mminDate.toDate() : new Date()}
      maxDate={mmaxDate?.isValid() ? mmaxDate.toDate() : null}
      dateFormat="dd/MM/yyyy"
      popperProps={{
        positionFixed: true,
      }}
      disabled={isDisabled}
      customInput={
        <InputMask
          className={styles.input}
          style={style}
          mask="99/99/9999"
          value={valueIsValid ? valueDate.format('DD/MM/YYYY') : ''}
        />
      }
    />
  );
};

export default Calendar;

Calendar.propTypes = {
  setValue: PropTypes.func.isRequired,
};
