import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './FieldList.module.scss';
import FieldItem from './FieldItem/FieldItem';
import { useTranslation } from 'react-i18next';

const FieldList = ({ fields, setFields, showFieldsFeedback, onEditFinish }) => {
  const { t } = useTranslation();
  const handleSetField = useCallback(
    data => {
      setFields(f => {
        const newData = [...f];

        newData.forEach((item, j) => {
          if (item.title === data.title) {
            newData[j] = data;
          }
        });

        return newData;
      });
    },
    [setFields]
  );

  const hasSubFields = data => {
    return data.some(item => item.subfields);
  };

  const content = useMemo(() => {
    return fields
      ? fields.map((field, i) => {
          return (
            <FieldItem
              key={field.title}
              setField={setFields !== undefined ? handleSetField : () => {}}
              showFieldsFeedback={showFieldsFeedback ?? true}
              enableFieldsFeedbackUpdate={setFields !== undefined}
              onEditFinish={onEditFinish}
              field={field}
              index={i + 1}
            />
          );
        })
      : '';
  }, [fields, handleSetField, showFieldsFeedback, onEditFinish, setFields]);

  return (
    <div className={hasSubFields(fields) ? styles.containerWithSubfields : styles.containerWithoutSubfields}>
      {content}
    </div>
  );
};

FieldList.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
};

export default FieldList;
