import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CustomFields.module.scss';
import { Input, Radio, StarRating, Menu, TextArea, Calendar } from 'components/template';
import InputNumber from 'components/template/InputNumber/InputNumber';
import PropTypes from 'prop-types';
import { MdCalendarMonth, MdInfo, MdAccessTime } from 'react-icons/md';
import axios from 'api';
import { Spinner } from 'components/template';
import InputMask from 'react-input-mask';

const CustomFields = ({ fields, setFormFields, errors, className, disableAll = false }) => {
  const [matchFields, setMatchFields] = useState([]);
  const [dynamicOptions, setDynamicOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    if (hasInitialized) {
      setFormFields(matchFields);
    } else {
      setHasInitialized(true);
    }
  }, [matchFields, setFormFields, hasInitialized]);

  useEffect(() => {
    setMatchFields(fields);
  }, [fields]);

  useEffect(() => {
    fields.forEach(field => {
      if (field.type === 'select_dinamico' && field.endpoint) {
        const fetchDynamicOptions = async () => {
          setLoading(prevLoading => ({
            ...prevLoading,
            [field.name]: true,
          }));
          try {
            const response = await axios.get(field.endpoint);
            const { data } = response;

            if (data && data.results) {
              const arr = data.results.map(item => ({
                value: item.id,
                label: item.name,
              }));

              setDynamicOptions(prevOptions => ({
                ...prevOptions,
                [field.name]: arr,
              }));
            }
          } catch (error) {
            console.error(`Error fetching options for ${field.name}:`, error);
          } finally {
            setLoading(prevLoading => ({
              ...prevLoading,
              [field.name]: false,
            }));
          }
        };
        fetchDynamicOptions();
      }
    });
  }, [fields]);

  const renderField = (field, matchField, setMatchFields, errors, disableAll) => {
    const fieldError = Array.isArray(errors) && errors.find(error => error.field === field.name);

    const renderErrorMessage = () => {
      return fieldError ? <p style={{ color: 'red', fontSize: '14px' }}>{fieldError.message}</p> : null;
    };

    const updateFieldValue = value => {
      setMatchFields(prev => prev.map(item => (item.name === field.name ? { ...item, value } : item)));
    };

    const clearFieldValue = () => {
      setMatchFields(prev => prev.map(item => (item.name === field.name ? { ...item, value: '' } : item)));
    };

    switch (field.type) {
      case 'select_simples':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <Menu
              title={field.label}
              className={`${styles.largeSelect} ${fieldError ? styles.largeSelectError : ''}`}
              placeholder={field.label}
              options={field.options}
              value={matchField.value}
              setValue={updateFieldValue}
              onClear={clearFieldValue}
              allowEmpty
              disabled={disableAll}
            />
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'select_dinamico':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            {loading[field.name] ? (
              <Spinner />
            ) : (
              <Menu
                title={field.label}
                className={`${styles.largeSelect} ${fieldError ? styles.largeSelectError : ''}`}
                placeholder={field.label}
                options={dynamicOptions[field.name] || []}
                value={matchField.value}
                setValue={updateFieldValue}
                onClear={clearFieldValue}
                disabled={disableAll}
                allowEmpty
              />
            )}
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'text_area':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            {disableAll ? (
              <div className={`${styles.textAreaField} ${styles.textAreaFieldDisabled}`}>
                {matchField.value || <span style={{ color: '#aaa' }}>{field.placeholder}</span>}
              </div>
            ) : (
              <TextArea
                textAreaClassName={`${styles.textAreaField} ${fieldError ? styles.largeSelectError : ''}`}
                value={matchField.value}
                onChange={e => updateFieldValue(e.target.value)}
                placeholder={field.placeholder}
                disabled={disableAll}
                maxLength={field.max_lenght}
              />
            )}
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'text':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <Input
              className={styles.largeTextField}
              style={fieldError ? { border: '1px solid red' } : undefined}
              type="text"
              name={field.name}
              value={matchField.value}
              onChange={e => updateFieldValue(e.target.value)}
              maxLength={field.max_lenght}
              placeholder={field.placeholder}
              disabled={disableAll}
            />
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'stars':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <StarRating rate={matchField.value} setValue={updateFieldValue} size={25} editable={!disableAll} />
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'date':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <div className={styles.calendar}>
              <Calendar
                minDate="1975-01-01"
                value={matchField.value}
                setValue={updateFieldValue}
                style={fieldError ? { border: '1px solid red' } : undefined}
                isDisabled={disableAll}
              />
              <div className={styles.calendarIcon}>
                <MdCalendarMonth />
              </div>
            </div>
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'hour':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <div className={styles.timeInputWrapper}>
              <InputMask
                mask="99:99"
                placeholder="HH:MM"
                className={styles.timeInput}
                style={fieldError ? { border: '1px solid red' } : undefined}
                value={matchField.value || ''}
                onChange={e => updateFieldValue(e.target.value)}
                disabled={disableAll}
              />
              <div className={styles.timeIcon}>
                <MdAccessTime />
              </div>
            </div>
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'number':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <InputNumber
              className={styles.numberField}
              style={fieldError ? { border: '1px solid red' } : undefined}
              value={matchField.value}
              name={field.name}
              maxLength={3}
              onChange={updateFieldValue}
              disabled={disableAll}
            />
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'number2':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <InputNumber
              className={styles.numberField2}
              style={fieldError ? { border: '1px solid red' } : undefined}
              value={matchField.value}
              name={field.name}
              maxLength={2}
              showButton={true}
              onChange={updateFieldValue}
              disabled={disableAll}
            />
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'radio':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <div className={styles.radioContainer}>
              {field.options.map(option => (
                <Radio
                  key={option.value}
                  label={option.label}
                  checked={matchField.value === option.value}
                  onChange={() => updateFieldValue(option.value)}
                  disabled={disableAll}
                />
              ))}
            </div>
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      case 'radio2':
        return (
          <div className={styles.formGroup} key={field.name}>
            <label className={styles.formLabel}>{field.label}:</label>
            <div className={styles.radioContainer}>
              {field.options.map(option => (
                <div key={option.value} className={styles.checkboxContainer}>
                  <Radio
                    key={option.value}
                    label={option.label}
                    checked={matchField.value?.includes(option.value) || false}
                    disabled={disableAll}
                    onClick={() => {
                      const currentSelections = matchField.value || [];
                      if (currentSelections.includes(option.value)) {
                        updateFieldValue(currentSelections.filter(val => val !== option.value));
                      } else {
                        if (currentSelections.length < field.max_options) {
                          updateFieldValue([...currentSelections, option.value]);
                        }
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            {field.description && (
              <p className={styles.info}>
                <MdInfo className={styles.infoIcon} />
                Ex: {field.description}
              </p>
            )}
            {renderErrorMessage()}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={className}>
      {fields.map(field =>
        renderField(field, matchFields.find(item => item.name === field.name) || {}, setMatchFields, errors, disableAll)
      )}
    </div>
  );
};

CustomFields.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  setFormFields: PropTypes.func.isRequired,
};

export default CustomFields;
