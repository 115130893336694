import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StarRating, TextArea, ToolTip } from 'components/template';
import styles from './FieldItem.module.scss';
import { CiCircleInfo } from 'react-icons/ci';

const FieldItem = ({
  field,
  index,
  setField,
  showFieldsFeedback = true,
  enableFieldsFeedbackUpdate = false,
  onEditFinish,
}) => {
  const user = useSelector(state => state.user);
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);
  const { t } = useTranslation();

  const updateSubfield = updatedSubfield => {
    const updatedField = {
      ...field,
      subfields: field.subfields.map(subfield =>
        subfield.title === updatedSubfield.title ? updatedSubfield : subfield
      ),
    };

    if (user.data?.organization?.teamId === 889) {
      const totalWeight = updatedField.subfields.reduce((acc, sub) => acc + (sub.weight || 0), 0);

      const weightedRateSum = updatedField.subfields.reduce((acc, sub) => acc + (sub.rate || 0) * (sub.weight || 0), 0);

      updatedField.rate = totalWeight > 0 ? weightedRateSum / totalWeight : 0;
    } else {
      const totalRate = updatedField.subfields.reduce((acc, sub) => acc + (sub.rate || 0), 0);

      updatedField.rate = totalRate / updatedField.subfields.length || 0;
    }

    setField(updatedField);
  };

  const renderFeedbackSection = () => {
    if (!showFieldsFeedback) return null;

    if (isEditingFeedback) {
      return (
        <>
          <TextArea value={field.feedback || ''} onChange={e => setField({ ...field, feedback: e.target.value })} />
          <span
            onClick={() => {
              setIsEditingFeedback(false);
              if (onEditFinish) {
                onEditFinish();
              }
            }}
            className={styles.feedbackTrigger}
          >
            {t('field.con')}
          </span>
        </>
      );
    }

    if (field.feedback) {
      return (
        <div className={styles.withFeedback}>
          <p>{field.feedback}</p>
          {enableFieldsFeedbackUpdate && (
            <span onClick={() => setIsEditingFeedback(true)} className={styles.feedbackTrigger}>
              {t('field.edit')}
            </span>
          )}
        </div>
      );
    }

    return enableFieldsFeedbackUpdate ? (
      <span onClick={() => setIsEditingFeedback(true)} className={styles.feedbackTrigger}>
        {t('field.click')}
      </span>
    ) : null;
  };

  const renderTextHelpSection = () => {
    if (field.textHelp) {
      return (
        <div dangerouslySetInnerHTML={{ __html: field.textHelp }} style={{ fontSize: '15px', marginTop: '10px' }} />
      );
    }
    return null;
  };

  const renderSubfields = () => {
    if (!field.subfields) return null;
    return (
      <div className={styles.subfields}>
        {field.subfields.map(subfield => (
          <div key={subfield.title} className={styles.subfield}>
            <div className={styles.info}>
              {subfield.info && (
                <ToolTip message={subfield.info} position={'top'} className={styles.tooltipContainer}>
                  <CiCircleInfo color="#5A00FF" style={{ height: '25px', width: '25px' }} />
                </ToolTip>
              )}
              <h5>{subfield.title}</h5>
            </div>
            <div className={styles.titleRating}>
              <StarRating
                editable
                setValue={rate => {
                  updateSubfield({ ...subfield, rate });
                }}
                rate={subfield.rate || 0}
                size={20}
              />
              <p>({isNaN(subfield.rate) ? '0.00' : Number(subfield.rate).toFixed(2)})</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.fieldItem}>
      <h3>
        {index}. {field.title}
      </h3>
      <div style={{ display: 'flex', gap: '10px' }}>
        <StarRating
          editable={!field.subfields}
          setValue={rate => setField({ ...field, rate })}
          rate={field.rate || 0}
          size={25}
        />
        <p>({isNaN(field.rate) ? '0.00' : Number(field.rate).toFixed(2)})</p>
      </div>
      {renderFeedbackSection()}
      {renderSubfields()}
      {renderTextHelpSection()}
    </div>
  );
};

FieldItem.propTypes = {
  field: PropTypes.shape({
    title: PropTypes.string.isRequired,
    rate: PropTypes.number,
    feedback: PropTypes.string,
    subfields: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        rate: PropTypes.number,
      })
    ),
  }).isRequired,
  index: PropTypes.number.isRequired,
  setField: PropTypes.func.isRequired,
  showFieldsFeedback: PropTypes.bool,
  enableFieldsFeedbackUpdate: PropTypes.bool,
  onEditFinish: PropTypes.func,
};

export default FieldItem;
