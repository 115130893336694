import { athletesData } from 'shared/athleteMocks';
import { stringifyFiltersWithNestedObjects } from 'shared/utility';
import axios from 'api';
import { updateObject } from 'shared/utility';
import Endpoints from 'endpoints';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { Creators as layoutCreators } from 'store/ducks/layout';

// TYPES
export const actionTypes = {
  UPDATE_ATHLETE_LISTS: 'athleteReport/UPDATE_ATHLETE_LISTS',
  GET_ATHLETE_DATA: 'athlete/GET_ATHLETE_DATA',
  GET_ATHLETE_DATA_SUCCESS: 'athlete/GET_ATHLETE_DATA_SUCCESS',
  GET_ATHLETE_DATA_FAILED: 'athlete/GET_ATHLETE_DATA_FAILED',
  GET_ATHLETE_DATA_ERROR_HANDLED: 'athlete/GET_ATHLETE_DATA_ERROR_HANDLED',

  GET_ATHLETE_REPORTS_PRO: 'athlete/GET_ATHLETE_REPORTS_PRO',
  GET_ATHLETE_REPORTS_PRO_SUCCESS: 'athlete/GET_ATHLETE_REPORTS_PRO_SUCCESS',
  GET_ATHLETE_REPORTS_PRO_FAILED: 'athlete/GET_ATHLETE_REPORTS_PROA_FAILED',
  GET_ATHLETE_REPORTS_PRO_ERROR_HANDLED: 'athlete/GET_ATHLETE_REPORTS_PRO_ERROR_HANDLED',
  RESET_ATHLETE_REPORTS_PRO: 'athlete/RESET_ATHLETE_REPORTS_PRO',

  GET_EVALUATION: 'athlete/GET_EVALUATION',
  GET_EVALUATION_SUCCESS: 'athlete/GET_EVALUATION_SUCCESS',
  GET_EVALUATION_FAILED: 'athlete/GET_EVALUATION_FAILED',
  GET_EVALUATION_ERROR_HANDLED: 'athlete/GET_EVALUATION_ERROR_HANDLED',
  RESET_EVALUATION_DATA: 'athlete/RESET_EVALUATION_DATA',

  SAVE_EVALUATION_INIT: 'athlete/SAVE_EVALUATION_INIT',
  SAVE_EVALUATION_START: 'athlete/SAVE_EVALUATION_START',
  SAVE_EVALUATION_SUCCESS: 'athlete/SAVE_EVALUATION_SUCCESS',
  SAVE_EVALUATION_FAILED: 'athlete/SAVE_EVALUATION_FAILED',
  SAVE_EVALUATION_ERROR_HANDLED: 'athlete/SAVE_EVALUATION_ERROR_HANDLED',
  RESET_SAVE_EVALUATION: 'athlete/RESET_SAVE_EVALUATION',

  GET_EVALUATIONS_LIST: 'athlete/GET_EVALUATIONS_LIST',
  GET_EVALUATIONS_LIST_SUCCESS: 'athlete/GET_EVALUATIONS_LIST_SUCCESS',
  GET_EVALUATIONS_LIST_FAILED: 'athlete/GET_EVALUATIONS_LIST_FAILED',
  GET_EVALUATIONS_LIST_ERROR_HANDLED: 'athlete/GET_EVALUATIONS_LIST_ERROR_HANDLED',
  RESET_EVALUATIONS_LIST: 'athlete/RESET_EVALUATIONS_LIST',

  GET_EVALUATIONS_DASHBOARD: 'athlete/GET_EVALUATIONS_DASHBOARD',
  GET_EVALUATIONS_DASHBOARD_SUCCESS: 'athlete/GET_EVALUATIONS_DASHBOARD_SUCCESS',
  GET_EVALUATIONS_DASHBOARD_FAILED: 'athlete/GET_EVALUATIONS_DASHBOARD_FAILED',

  POST_ATHLETE_MIGRATION: 'athlete/POST_ATHLETE_MIGRATION',
  POST_ATHLETE_MIGRATION_SUCCESS: 'athlete/POST_ATHLETE_MIGRATION_SUCCESS',
  POST_ATHLETE_MIGRATION_FAILED: 'athlete/POST_ATHLETE_MIGRATION_FAILED',

  SET_MIGRATION_SUCCESS: 'athlete/SET_MIGRATION_SUCCESS',
  CLEAR_MIGRATION_SUCCESS: 'athlete/CLEAR_MIGRATION_SUCCESS',

  GET_ATHLETE_PROJECTS: 'athlete/GET_ATHLETE_PROJECTS',
  GET_ATHLETE_PROJECTS_SUCCESS: 'athlete/GET_ATHLETE_PROJECTS_SUCCESS',
  GET_ATHLETE_PROJECTS_FAILED: 'athlete/GET_ATHLETE_PROJECTS_FAILED',

  DELETE_EVALUATIONS: 'athlete/DELETE_EVALUATIONS',
  DELETE_EVALUATIONS_SUCCESS: 'athlete/DELETE_EVALUATIONS_SUCCESS',
  DELETE_EVALUATIONS_ERROR: 'athlete/DELETE_EVALUATIONS_ERROR',

  RESET: 'athlete/RESET',
};

// INITIAL STATE
const initialState = {
  athleteData: {
    loading: false,
    error: null,
    data: {
      id: null,
      teamId: null,
      clubImg: null,
      clubShortName: null,
      img: null,
      shortName: null,
      fullName: null,
      role: null,
      age: null,
      country: null,
      marketValue: null,
      highestMarketValue: {
        value: null,
        date: null,
      },
      rate: null,
      performanceAmount: null,
      careerAmount: null,
      matchesAmount: null,
      evaluationsAmount: null,
      contactsAmount: null,
      negotiationsAmount: null,
      videosAmount: null,
      newsAmount: null,
    },
  },
  reports_pro: {
    count: 0,
    next: null,
    previous: null,
    results: null,
  },
  evaluationData: {
    saved: false,
    loading: false,
    error: null,
    data: {},
  },
  evaluationsList: {
    loading: false,
    error: null,
    data: [],
  },
  athleteMigration: {
    loading: false,
    data: [],
    error: null,
  },
};

// ACTIONS
export const Creators = {
  getAthleteData: athleteId => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_ATHLETE_DATA });
      const { data } = await axios.get(`${Endpoints.athletes}/${athleteId}/?save_visit=1`);
      const athleteDataMock = athletesData.find(athlete => String(athlete.id) === String(athleteId));
      const athleteData = {
        ...athleteDataMock,
        ...data,
        age: `'${data?.birthyear?.toString()?.slice(-2) ?? ''} (${data.age})`,
      };
      dispatch({
        type: actionTypes.GET_ATHLETE_DATA_SUCCESS,
        payload: { data: athleteData },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_ATHLETE_DATA_FAILED,
          payload: { error: ErrorMessages.resourceNotFound('erros.text9') },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_ATHLETE_DATA_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_ATHLETE_DATA_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getProjects: (athleteId, filters) => async dispatch => {
    dispatch({ type: actionTypes.GET_ATHLETE_PROJECTS });

    try {
      const queryString = stringifyFiltersWithNestedObjects(filters);
      const { data } = await axios.get(`${Endpoints.athletes}/${athleteId}/projects${queryString}`);

      dispatch({
        type: actionTypes.GET_ATHLETE_PROJECTS_SUCCESS,
        payload: { data: data },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_ATHLETE_PROJECTS_FAILED,
          payload: { error: ErrorMessages.resourceNotFound('erros.text9') },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_ATHLETE_PROJECTS_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_ATHLETE_PROJECTS_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getReportsPro: (athleteId, filters) => async dispatch => {
    dispatch({ type: actionTypes.GET_ATHLETE_REPORTS_PRO });

    try {
      const queryString = stringifyFiltersWithNestedObjects(filters);
      const { data } = await axios.get(`${Endpoints.athletes}/${athleteId}/reports-pro${queryString}`);

      dispatch({
        type: actionTypes.GET_ATHLETE_REPORTS_PRO_SUCCESS,
        payload: { data: data },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_ATHLETE_REPORTS_PRO_FAILED,
          payload: { error: ErrorMessages.resourceNotFound('erros.text9') },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.GET_ATHLETE_REPORTS_PRO_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_ATHLETE_REPORTS_PRO_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getReportPro: async (athleteId, reportProId) => {
    try {
      const { data } = await axios.get(`${Endpoints.athletes}/${athleteId}/reports-pro/${reportProId}`);
      return data;
    } catch (err) {
      return {
        error: 'erros.text8',
      };
    }
  },
  resetReportPro: () => ({ type: actionTypes.RESET_ATHLETE_REPORTS_PRO }),
  getEvaluation: ({ athleteId, id }) => dispatch => {
    dispatch({ type: actionTypes.GET_EVALUATION });
    axios
      .get(`athletes/${athleteId}/evaluations/${id}/`)
      .then(res => {
        const selectedEvaluation = { ...res.data, ...res.data.data };
        // delete selectedEvaluation.data;
        if (selectedEvaluation) {
          dispatch({ type: actionTypes.GET_EVALUATION_SUCCESS, payload: { data: selectedEvaluation } });
        } else {
          dispatch({
            type: actionTypes.GET_EVALUATION_FAILED,
            payload: { error: 'erros.text10' },
          });
        }
      })
      .catch(err => {
        dispatch({
          type: actionTypes.GET_EVALUATION_FAILED,
          payload: { error: 'erros.text10' },
        });
      });
  },
  getEvaluationErrorHandled: () => ({
    type: actionTypes.GET_EVALUATION_ERROR_HANDLED,
  }),
  resetEvaluation: () => ({ type: actionTypes.RESET_EVALUATION_DATA }),
  getEvaluationsList: athleteId => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET_EVALUATIONS_LIST });
      const {
        data: { count, results },
      } = await axios.get(Endpoints.athleteEvaluations(athleteId));
      // const promises = [];
      const output = results.map(result => {
        return {
          date: result?.evaluation_date ?? result?.created_at,
          author: result?.author,
          generalEvaluation: result?.general_evaluation,
          id: result?.id,
          team: {
            id: result?.team_data?.id,
            emblem: result?.team_data?.emblem ?? '',
            name: result?.team_data?.nickname,
          },
          current_team: result?.target_data?.current_team,
          athlete: {
            id: result?.target_data?.id ?? '',
            name: result?.target_data?.fullname ?? '',
            nickname: result?.target_data?.nickname ?? '',
            img: result?.target_data?.photo ?? '',
          },
          contract_info: result?.target_data?.contract_info,
          mode: result.mode,
          recommendation: result?.recommendation,
          priority: result?.priority,
        };
      });
      dispatch({
        type: actionTypes.GET_EVALUATIONS_LIST_SUCCESS,
        payload: {
          observationsAmount: count,
          performed: output,
        },
      });
    } catch (err) {
      logger.error(err.message);
      if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_LIST_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else if (err?.response?.status === 404) {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_LIST_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_LIST_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetEvaluationsList: () => ({ type: actionTypes.RESET_EVALUATIONS_LIST }),
  getEvaluationsDashboard: (athleteId, filters) => async dispatch => {
    try {
      const queryString = stringifyFiltersWithNestedObjects(filters);
      dispatch({ type: actionTypes.GET_EVALUATIONS_DASHBOARD });
      const response = await axios.get(Endpoints.athleteDashboard(athleteId) + queryString);

      dispatch({
        type: actionTypes.GET_EVALUATIONS_DASHBOARD_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } catch (err) {
      logger.error(err.message);
      if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_DASHBOARD_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else if (err?.response?.status === 404) {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_DASHBOARD_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_EVALUATIONS_DASHBOARD_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },

  saveEvaluationInit: () => ({ type: actionTypes.SAVE_EVALUATION_INIT }),

  saveEvaluation: ({ athleteId, id = null, evaluationData }) => async dispatch => {
    try {
      dispatch({ type: actionTypes.SAVE_EVALUATION_START });
      if (!id) {
        const { data } = await axios.post(Endpoints.newEvaluation(athleteId), evaluationData);
        const selectedEvaluation = { ...data, ...data.data };
        dispatch({ type: actionTypes.SAVE_EVALUATION_SUCCESS, payload: { data: selectedEvaluation } });
      } else {
        const { data } = await axios.put(Endpoints.saveEvaluation(athleteId, id), evaluationData);
        const selectedEvaluation = { ...data, ...data.data };
        dispatch({ type: actionTypes.SAVE_EVALUATION_SUCCESS, payload: { data: selectedEvaluation } });
      }
    } catch (err) {
      logger.error(err.message);
      if (err?.response?.status === 401) {
        dispatch({
          type: actionTypes.SAVE_EVALUATION_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else if (err?.response?.status === 402) {
        dispatch(layoutCreators.showExcededPlanLimit());
        dispatch({
          type: actionTypes.SAVE_EVALUATION_FAILED,
          payload: { error: null },
        });
      } else {
        dispatch({
          type: actionTypes.SAVE_EVALUATION_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  saveEvaluationErrorHandled: () => ({
    type: actionTypes.GET_EVALUATION_ERROR_HANDLED,
  }),
  resetSaveEvaluation: () => ({ type: actionTypes.RESET_SAVE_EVALUATION }),
  reset: () => ({ type: actionTypes.RESET }),
  migrateAthleteData: ({ athleteId, customAthleteId }) => async dispatch => {
    try {
      dispatch({ type: actionTypes.POST_ATHLETE_MIGRATION });

      const payload = {
        custom_athlete: customAthleteId,
        athlete: athleteId,
      };

      const { data } = await axios.post(`${Endpoints.athleteMigration}`, payload);

      dispatch({ type: actionTypes.SET_MIGRATION_SUCCESS });
      dispatch({
        type: actionTypes.POST_ATHLETE_MIGRATION_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.POST_ATHLETE_MIGRATION_FAILED,
          payload: { error: ErrorMessages.resourceNotFound('erros.text9') },
        });
      } else if (response?.status === 401) {
        dispatch({
          type: actionTypes.POST_ATHLETE_MIGRATION_FAILED,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.POST_ATHLETE_MIGRATION_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  deleteEvaluationsLists: (evaluations, athlete_id) => async dispatch => {
    dispatch({ type: actionTypes.DELETE_EVALUATIONS });
    const url = Endpoints.evaluations;

    try {
      await Promise.all(evaluations.map(evaluation => axios.delete(`${url}/${evaluation}`)));

      await dispatch({
        type: actionTypes.DELETE_EVALUATIONS_SUCCESS,
      });

      dispatch(Creators.getEvaluationsList(athlete_id));
    } catch (err) {
      dispatch({
        type: actionTypes.DELETE_EVALUATIONS_ERROR,
        payload: { error: 'erros.text21' },
      });
    }
  },
};

// REDUCER
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ATHLETE_DATA:
      return { ...state, athleteData: { ...state.athleteData, loading: true } };
    case actionTypes.GET_ATHLETE_DATA_SUCCESS:
      return {
        ...state,
        athleteData: {
          ...state.athleteData,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_ATHLETE_DATA_FAILED:
      return {
        ...state,
        athleteData: {
          ...state.athleteData,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_DATA_ERROR_HANDLED:
      return { ...state, athleteData: { ...state.athleteData, error: null } };

    // reports pro
    case actionTypes.GET_ATHLETE_REPORTS_PRO:
      return { ...state, reports_pro: { ...state.reports_pro, loading: true } };
    case actionTypes.GET_ATHLETE_REPORTS_PRO_SUCCESS:
      return {
        ...state,
        reports_pro: {
          ...action.payload.data,
          loading: false,
        },
      };
    case actionTypes.GET_ATHLETE_REPORTS_PRO_FAILED:
      return {
        ...state,
        reports_pro: {
          ...state.reports_pro,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ATHLETE_REPORTS_PRO_ERROR_HANDLED:
      return { ...state, reports_pro: { ...state.reports_pro, error: null } };

    case actionTypes.RESET_ATHLETE_REPORTS_PRO:
      return { ...state, reports_pro: initialState.reports_pro };

    // evaluations
    case actionTypes.GET_EVALUATION:
      return {
        ...state,
        evaluationData: { ...state.evaluationData, loading: true },
      };
    case actionTypes.GET_EVALUATION_SUCCESS:
      return {
        ...state,
        evaluationData: {
          ...state.evaluationData,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_EVALUATION_FAILED:
      return {
        ...state,
        evaluationData: {
          ...state.evaluationData,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_EVALUATION_ERROR_HANDLED:
      return {
        ...state,
        evaluationData: { ...state.evaluationData, error: null },
      };
    case actionTypes.RESET_EVALUATION_DATA:
      return { ...state, evaluationData: initialState.evaluationData };

    case actionTypes.GET_EVALUATIONS_LIST:
      return {
        ...state,
        evaluationsList: { ...state.evaluationsList, loading: true, error: null },
      };
    case actionTypes.GET_EVALUATIONS_LIST_SUCCESS:
      return {
        ...state,
        evaluationsList: {
          ...state.evaluationsList,
          loading: false,
          data: action.payload.performed,
        },
        evaluationsAmount: action.payload.evaluationsAmount,
      };
    case actionTypes.GET_EVALUATIONS_LIST_FAILED:
      return {
        ...state,
        evaluationsList: {
          ...state.evaluationsList,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_EVALUATIONS_ERROR_HANDLED:
      return {
        ...state,
        evaluationsList: { ...state.evaluationsList, error: null },
      };
    case actionTypes.RESET_EVALUATIONS_LIST:
      return { ...state, evaluationsList: initialState.evaluationsList };

    case actionTypes.SAVE_EVALUATION_INIT: {
      const updatedEvaluationData = updateObject(state.evaluationData, { saved: false });
      return updateObject(state, {
        evaluationData: updatedEvaluationData,
      });
    }

    case actionTypes.SAVE_EVALUATION_START: {
      const updatedEvaluationData = updateObject(state.evaluationData, { loading: true });
      return updateObject(state, {
        evaluationData: updatedEvaluationData,
      });
    }

    case actionTypes.SAVE_EVALUATION_SUCCESS: {
      const updatedEvaluationData = updateObject(state.evaluationData, {
        loading: false,
        saved: true,
        data: action.payload.data,
      });
      return updateObject(state, {
        evaluationData: updatedEvaluationData,
      });
    }

    case actionTypes.SAVE_EVALUATION_FAILED: {
      const updatedEvaluationData = updateObject(state.evaluationData, {
        loading: false,
        error: action.payload.error,
      });
      return updateObject(state, {
        evaluationData: updatedEvaluationData,
      });
    }

    case actionTypes.SAVE_EVALUATION_ERROR_HANDLED: {
      const updatedEvaluationData = updateObject(state.evaluationData, { error: null });
      return updateObject(state, {
        evaluationData: updatedEvaluationData,
      });
    }

    case actionTypes.RESET_SAVE_EVALUATION:
      return {
        ...state,
        evaluationData: initialState.evaluationData,
      };

    case actionTypes.RESET:
      return initialState;

    case actionTypes.UPDATE_ATHLETE_LISTS:
      return {
        ...state,
        athleteData: {
          ...state.athleteData,
          data: {
            ...state.athleteData.data,
            lists: action.payload.lists,
            counts: {
              ...state.athleteData.counts,
              lists: action.payload.lists.length,
            },
          },
        },
      };
    case actionTypes.POST_ATHLETE_MIGRATION:
      return {
        ...state,
        athleteMigration: { ...state.athleteMigration, loading: true, error: null },
      };
    case actionTypes.POST_ATHLETE_MIGRATION_SUCCESS:
      return {
        ...state,
        athleteMigration: {
          ...state.athleteMigration,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.POST_ATHLETE_MIGRATION_FAILED:
      return {
        ...state,
        athleteMigration: {
          ...state.athleteMigration,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.SET_MIGRATION_SUCCESS:
      return {
        ...state,
        migrationSuccess: true,
      };
    case actionTypes.CLEAR_MIGRATION_SUCCESS:
      return {
        ...state,
        migrationSuccess: false,
      };
    case actionTypes.GET_ATHLETE_PROJECTS:
      return {
        ...state,
        projects: [],
        loading: true,
        error: null,
      };
    case actionTypes.GET_ATHLETE_PROJECTS_FAILED:
      return {
        ...state,
        projects: [],
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.GET_ATHLETE_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        error: null,
      };
    case actionTypes.DELETE_EVALUATIONS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_EVALUATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_EVALUATIONS_ERROR:
      return {
        ...initialState,
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.GET_EVALUATIONS_DASHBOARD:
      return {
        ...state,
        evaluationsDashboard: {
          ...state.evaluationsDashboard,
          loading: true,
          error: null,
        },
      };
    case actionTypes.GET_EVALUATIONS_DASHBOARD_SUCCESS:
      return {
        ...state,
        evaluationsDashboard: {
          ...state.evaluationsDashboard,
          loading: false,
          data: action.payload.data,
        },
      };
    case actionTypes.GET_EVALUATIONS_DASHBOARD_FAILED:
      return {
        ...state,
        evaluationsDashboard: {
          ...state.evaluationsDashboard,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

export const updateLists = (lists, athleteId) => async dispatch => {
  dispatch({
    type: actionTypes.UPDATE_ATHLETE_LISTS,
    payload: {
      lists,
      athleteId,
    },
  });
};
