import React, { useCallback } from 'react';
import CustomFields from '../CustomFields/CustomFields';
import SideSection from '../SideSection/SideSection';
import styles from './ModelEvaluation.module.scss';
import { useTranslation } from 'react-i18next';

const ModelEvaluation = ({
  evaluationsFields,
  opinionFields,
  contextFields,
  setDataFields,
  errors,
  setEvaluationsFields,
}) => {
  const { t } = useTranslation();

  const updateParecerFields = useCallback(
    newFields => {
      setDataFields(prevDataFields => ({
        ...prevDataFields,
        opinion_fields: [...newFields],
      }));
    },
    [setDataFields]
  );

  const updateContextoFields = useCallback(
    newFields => {
      setDataFields(prevDataFields => ({
        ...prevDataFields,
        context_fields: [...newFields],
      }));
    },
    [setDataFields]
  );

  return (
    <div className={styles.container}>
      <div className={styles.opinionFields}>
        <h3>{t('field.pare')}</h3>
        <CustomFields fields={opinionFields} setFormFields={updateParecerFields} errors={errors.opinion} />
      </div>
      <div className={styles.evaluationFields}>
        <h3>{t('club.box15')}</h3>
        <SideSection fields={evaluationsFields} setFields={setEvaluationsFields} />
      </div>
      <div className={styles.contextFields}>
        <h3>{t('athleteDashboard.context')}</h3>
        <CustomFields fields={contextFields} setFormFields={updateContextoFields} errors={errors.context} />
      </div>
    </div>
  );
};

export default ModelEvaluation;
