import axios from 'axios';
import store from 'store';
import { Creators as UserCreators } from 'store/ducks/user';

const baseURL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''
  }/${process.env.REACT_APP_API_BASE}`;
// const baseURL = `https://develop.rededofutebol.com.br/api/v1/`;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(config => {
  const options = config;
  try {
    const { accessToken } = store.getState().user;
    if (accessToken && !config.headers.authorization) options.headers.authorization = `Bearer ${accessToken}`;

    const { url } = config;
    if (!url[url.length - 1] === '/') options.url = `${config.url}/`;
  } catch (err) {
  }
  return options;
});

instance.interceptors.response.use(
  response => response,
  error => {
    try {
      const { response } = error;

      const statusCode = response?.status;
      const pathname = window.location.pathname;
      if (statusCode === 401 && !pathname.includes('/login')) {
        UserCreators.logoutAndRedirect();
      } else if (statusCode === 402 && !pathname.includes('/home')) {
        const method = error.config?.method ?? '';
        if (method.toLowerCase() === 'get') {
          localStorage.setItem('rdf_excededPlanLimit', 'true');
          window.location.href = '/home';
        }
      }
    } catch (e) {
      console.log('error on axios interceptors error', e);
    }
    return Promise.reject(error);
  }
);

const defaultLanguage = localStorage.getItem('language');
const acceptLanguage = defaultLanguage === 'en' ? 'en' : 'pt-br';

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.common['Accept-Language'] = acceptLanguage;

export default instance;

