import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Users.module.scss';
import { Avatar } from 'components/template';
import CenteredLoader from 'components/template/CenteredLoader';
import { Link } from 'react-router-dom';
import { getUsers } from 'store/ducks/myClub';
import { useDispatch, useSelector } from 'react-redux';
import UserDialog from './UserDialog';
import { useTranslation } from 'react-i18next';

function Users({ teamId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.myClub);
  const initialUserDialog = {
    isOpen: false,
    updatePage: false,
    removeUserName: null,
    removeUserId: null,
  };
  const [userDialog, setUserDialog] = useState(initialUserDialog);

  useEffect(() => {
    dispatch(getUsers(teamId));
  }, [teamId, dispatch]);

  useEffect(() => {
    if (userDialog.updatePage) {
      dispatch(getUsers(teamId));
      setUserDialog({
        ...initialUserDialog,
        updatePage: false,
      });
    }
  }, [userDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.container} /*key={item.id}*/>
        <div className={styles.title}>
          <h1>{t('myClub.users.title')}</h1>
          <span>{t('myClub.users.subtitle')}</span>
        </div>
        {users.loading && <CenteredLoader size="calc(100vh - 280px)" />}
        <div className={styles.athleteGrid}>
          {(users.loading ? [] : users.data).map(item => {
            const userName = `${item.first_name ?? ''} ${item.last_name ?? ''}`;
            return (
              <div className={styles.athleteCard}>
                <div className={styles.left}>
                  <Avatar className={styles.avatar} connectionsAvatar img={item.photo} name={userName} />
                  <div className={styles.right}>
                    <Link className={styles.name}>
                      <span>{userName}</span>
                    </Link>
                    <div className={styles.info}>
                      <span className={styles.occupation}>{item.occupation ?? ''}</span>
                    </div>
                    {item.city ? (
                      <div className={styles.infoCountry}>
                        {item.country && <img src={item.country} alt={''} draggable="false" />}
                        <span>{item.city}</span>
                      </div>
                    ) : (
                      <div className={styles.infoCountry}></div>
                    )}
                  </div>
                </div>
                {/*
                <div className={styles.bottons2}>
                  <IconButton
                    text={t('myClub.users.remove')}
                    icon={<MdExitToApp />}
                    className={styles.remove}
                    onClick={() => {
                      setUserDialog({
                        ...initialUserDialog,
                        removeUserId: item.id,
                        removeUserName: userName,
                        isOpen: true,
                      });
                    }}
                  /> <IconButton
                    text={t('myClub.users.editPermission')}
                    icon={<RiPencilFill />}
                    className={styles.editPermition}
                    disabled
                  /> 
                </div>*/}
              </div>
            );
          })}
        </div>
      </div>
      <UserDialog
        userDialog={userDialog}
        setUserDialog={setUserDialog}
        initialUserDialog={initialUserDialog}
        teamId={teamId}
      />
    </>
  );
}
export default withRouter(Users);
