import ImgAvatar from 'assets/avatar-atleta.png';
import ImgGremio from 'assets/escudos/gremio.svg';
import ImgBrazil from 'assets/escudos/brasil.png';
import ImgSaoPaulo from 'assets/escudos/sao_paulo.png';
import FakeAvatar from 'assets/navbar-avatar.png';
import AthleteImg from 'assets/funil-avatar-atleta.png';
import GremioBanner from 'assets/atleta-divulgacao.png';
import ImgSport from 'assets/escudos/sport.png';
import ImgYoutube from 'assets/video_youtube.png';
import ImgABC from 'assets/escudos/abc.png';
import RomildoBolzan from 'assets/Romildo-Bolzan.jpg';
import DudaKroeff from 'assets/duda-kroeff.jpg';
import AlbertoGuerra from 'assets/alberto_guerra.jpg';
import KlausCamara from 'assets/klaus_camara.jpg';
import NewsImg from 'assets/noticia1.png';

export const users = [
  {
    email: 'adriano.martins@gmail.com',
    password: 'teste123',
    data: {
      name: 'Adriano Martins',
      img: FakeAvatar,
      organization: {
        shortName: 'Grêmio',
        fullName: 'Grêmio Foot-ball Porto Alegrense',
        teamId: 1098,
        clubImg: ImgGremio,
        city: 'Porto Alegre, Rio Grande do Sul, Brasil',
        countryImg: ImgBrazil,
      },
    },
  },
];

export const notificationsMock = [
  {
    id: 1,
    img: FakeAvatar,
    message: '<a>Lucas Sachet</a> iniciou uma nova negociação com o atleta <a>Lucas Barrios</>',
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 2,
    img: FakeAvatar,
    message: '<a>Lucas Sachet</a> adicionou o relatório <a>41 Copa Gazetinha de Futebol</>',
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 3,
    img: FakeAvatar,
    message: '<a>Lucas Sachet</a> iniciou uma nova negociação com o atleta <a>Lucas Barrios</>',
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 4,
    img: FakeAvatar,
    message: '<a>Lucas Sachet</a> adicionou o relatório <a>41 Copa Gazetinha de Futebol</>',
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
];

export const chatMock = [
  {
    id: 1,
    img: FakeAvatar,
    text_message: 'Boa tarde! Muito feliz por fazer parte da sua rede agora',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 2,
    img: FakeAvatar,
    text_message: 'Boa tarde!',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 3,
    img: FakeAvatar,
    text_message: 'Boa tarde! Muito feliz por fazer parte da sua rede agora',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 4,
    img: FakeAvatar,
    text_message: 'Boa tarde!',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 5,
    img: FakeAvatar,
    text_message: 'Boa tarde! Muito feliz por fazer parte da sua rede agora',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 6,
    img: FakeAvatar,
    text_message: 'Boa tarde!',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 7,
    img: FakeAvatar,
    text_message: 'Boa tarde! Muito feliz por fazer parte da sua rede agora',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 8,
    img: FakeAvatar,
    text_message: 'Boa tarde!',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 9,
    img: FakeAvatar,
    text_message: 'Boa tarde! Muito feliz por fazer parte da sua rede agora',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
  {
    id: 10,
    img: FakeAvatar,
    text_message: 'Boa tarde!',
    receiver: {},
    sender: { user_name: 'Micael' },
    published_at: '2023-03-31T09:59:15.085050-03:00',
  },
];

export const monitoringMock = [
  {
    id: 1,
    athleteImg: AthleteImg,
    message: 'O atleta <a>Lucas Barrios</a> atuou 93 minutos na partida <a>Colo-Colo x Palmeiras</a> em 03/08/2018',
  },
  {
    id: 2,
    athleteImg: AthleteImg,
    message: 'O atleta <a>Lucas Barrios</a> se transferiu para a equipe <a>Colo-Colo/CHI</a>',
  },
  {
    id: 3,
    athleteImg: AthleteImg,
    message: '<a>Lucas Sachet</a> classificou atleta <a>Lucas Barrios</a> para Monitorar',
  },
];

export const searchMocks = [
  {
    title: 'Atletas Profissionais',
    type: 'athlete',
    amount: 145,
    items: [
      {
        id: 1,
        athleteImg: ImgAvatar,
        athleteClubImg: ImgGremio,
        athleteRating: 4.6,
        athleteShortName: 'Fernando Henrique',
        athleteFullName: 'Fernando Henrique do Nascimento Pereira',
        athleteAge: '01 (16 anos)',
        athletePosition: 'Centroavante',
        athleteCountry: ImgBrazil,
      },
      {
        id: 2,
        athleteImg: ImgAvatar,
        athleteClubImg: ImgGremio,
        athleteRating: 5,
        athleteShortName: 'Fernando Henrique',
        athleteFullName: 'Fernando Henrique do Nascimento Pereira',
        athleteAge: '01 (16 anos)',
        athletePosition: 'Centroavante',
        athleteCountry: ImgBrazil,
      },
    ],
  },
  {
    title: 'Atletas de Base',
    type: 'athlete',
    amount: 145,
    items: [
      {
        id: 3,
        athleteImg: ImgAvatar,
        athleteClubImg: ImgGremio,
        athleteRating: 4.2,
        athleteShortName: 'Fernando Henrique',
        athleteFullName: 'Fernando Henrique do Nascimento Pereira',
        athleteAge: '01 (16 anos)',
        athletePosition: 'Centroavante',
        athleteCountry: ImgBrazil,
      },
      {
        id: 4,
        athleteImg: ImgAvatar,
        athleteClubImg: ImgGremio,
        athleteRating: 4.56,
        athleteShortName: 'Fernando Henrique',
        athleteFullName: 'Fernando Henrique do Nascimento Pereira',
        athleteAge: '01 (16 anos)',
        athletePosition: 'Centroavante',
        athleteCountry: ImgBrazil,
      },
    ],
  },
  {
    title: 'Clubes',
    type: 'club',
    amount: 145,
    items: [
      {
        id: 1098,
        clubImg: ImgGremio,
        clubShortName: 'Grêmio',
        clubFullName: 'Grêmio Foot-ball Porto Alegrense',
        clubCity: 'Porto Alegre, Rio Grande do Sul, Brasil',
        clubCountry: ImgBrazil,
      },
      {
        id: 2,
        clubImg: ImgGremio,
        clubShortName: 'Grêmio',
        clubFullName: 'Grêmio Foot-ball Porto Alegrense',
        clubCity: 'Porto Alegre, Rio Grande do Sul, Brasil',
        clubCountry: ImgBrazil,
      },
    ],
  },
];

export const athletes = [
  {
    id: 119210,
    shortName: 'Fernando Henrique',
    fullName: 'Fernando Henrique do Nascimento Pereira',
    age: "'01 (16 anos)",
    role: 'Centroavante',
    img: AthleteImg,
    country: ImgBrazil,
    teamId: 1098,
    clubImg: ImgGremio,
    clubShortName: 'Grêmio',
  },
];

export const athletesEvaluations = [
  {
    id: 1,
    target: 119210,
    teamId: 1098,
    mode: 'LATERAIS',
    mode_name: 'Laterais',
    author: 1,
    author_name: 'Adriano Martins',
    date: '08/07/2017',
    observationMethod: 'Observação de Vídeo',
    evaluationMode: 'profissionalCentroavante',
    priority: 'media',
    targeting: 'contratar',
    values: {
      feedback: 'Lorem Ipsum dolor sit amet',
      teamContext: 'Os iusti meditabitur sapientiam, Et lingua eius loquetur indicium.',
      fields: [
        {
          title: 'Aspecto Tático',
          feedback: 'Beatus vir qui suffert tentationem, Quoniqm cum probates fuerit accipient coronam vitae.',
          subfields: [
            {
              title: 'Movimentos diagonais nas costas da defesa',
              rate: 1,
            },
            {
              title: 'Atuar no limite dos defensores',
              rate: 2,
            },
            {
              title: 'Atrair a marcação (flutuação nas costas dos volantes)',
              rate: 2,
            },
            {
              title: 'Pressão no Adversário',
              rate: 2,
            },
            {
              title: 'Fechamento de Linhas de Passe',
              rate: 2,
            },
          ],
        },
        {
          title: 'Aspecto Técnico',
          feedback: 'Kyrie, fons bonitatis. Kyrie, ignis divine, eleison.',
          subfields: [
            {
              title: 'Jogo Aéreo',
              rate: 3,
            },
            {
              title: 'Jogo de Pivô',
              rate: 3,
            },
            {
              title: 'Tabelas 1-2',
              rate: 3,
            },
            {
              title: 'Gols',
              rate: 3,
            },
            {
              title: 'Expectativa de Gols',
              rate: 3,
            },
            {
              title: 'Finalização',
              rate: 3,
            },
            {
              title: 'Proteção da bola',
              rate: 3,
            },
            {
              title: 'Duelos Individuais',
              rate: 3,
            },
          ],
        },
        {
          title: 'Aspecto Físico',
          feedback: 'O quam sancta, quam serena, quam benigma, quam amoena',
          subfields: [
            {
              title: 'Força',
              rate: 4,
            },
            {
              title: 'Potência',
              rate: 4,
            },
            {
              title: 'Perfil corporal',
              rate: 4,
            },
            {
              title: 'Impulsão',
              rate: 4,
            },
          ],
        },
        {
          title: 'Psicológico/Outros',
          feedback: 'esse virgo creditur.',
          subfields: [
            {
              title: 'Competitividade',
              rate: 2.5,
            },
            {
              title: 'Agressividade',
              rate: 2.5,
            },
            {
              title: 'Ambição de fazer gol',
              rate: 2.5,
            },
          ],
        },
        {
          title: 'Técnico Teste',
          rate: 4,
          feedback: 'Kyrie, fons bonitatis. Kyrie, ignis divine, eleison.',
        },
      ],
    },
  },
];

export const defaultClubEvaluationFields = [
  {
    id: 'B999',
    teamId: 152,
    mode_name: 'Relatório de Jogo',
    mode: 'RELATORIO-DE-JOGO',
  },
  {
    id: 'B991',
    teamId: 152,
    mode_name: 'Zagueiros Botafogo',
    mode: 'ZAGUEIROS-BOTAFOGO',
    data: {
      evaluation_fields: [
        {
          title: 'Aspecto Tático',
          rate: 0,
          subfields: [
            {
              title: 'Postura tática em relação à bola',
              rate: 0,
            },
            {
              title: 'Posicionamento em relação aos companheiros',
              rate: 0,
            },
          ],
        },
        {
          title: 'Aspecto Técnico',
          rate: 0,
          subfields: [
            {
              title: '1x1 defensivo',
              rate: 0,
            },
            {
              title: 'Saídas de gol (bola na área)',
              rate: 0,
            },
            {
              title: 'Reposição de bola',
              rate: 0,
            },
            {
              title: 'Defesas difíceis',
              rate: 0,
            },
          ],
        },
        {
          title: 'Aspecto Físico',
          rate: 0,
          subfields: [
            {
              title: 'Biotipo (estatura e porte físico)',
              rate: 0,
            },
            {
              title: 'Velocidade em ações de saída',
              rate: 0,
            },
            {
              title: 'Velocidade de reação',
              rate: 0,
            },
          ],
        },
        {
          title: 'Psicológico/Outros',
          rate: 0,
          subfields: [
            {
              title: 'Liderança em campo',
              rate: 0,
            },
            {
              title: 'Vivência em outros clubes/competições',
              rate: 0,
            },
            {
              title: 'Idade/Projeção',
              rate: 0,
            },
            {
              title: 'Coragem',
              rate: 0,
            },
            {
              title: 'Imposição',
              rate: 0,
            },
          ],
        },
      ],
      opinion_fields: [
        //DROPLIST SIMPLES
        {
          label: 'Caracteristicas da Posição',
          name: 'caracteristicas_da_posicao',
          type: 'select_simples',
          description: 'Direcionamento',
          value: 'passador',
          required: true,
          options: [
            { value: 'passador', label: 'Passador' },
            { value: 'definidor', label: 'Definidor' },
            { value: 'palavra_composta', label: 'Palavra composta' },
          ],
        },
        //DROPLIST DINAMICO
        {
          label: 'Competições',
          name: 'competicoes',
          type: 'select_dinamico',
          value: 404,
          endpoint: 'competitions',
          description: 'TESTE',
          required: true,
        },
        //TEXTAREA
        {
          label: 'TextArea',
          name: 'textarea',
          type: 'text_area',
          value: '',
          description: 'TESTE',
          max_lenght: 144,
          required: true,
        },
        //TEXTFIELD
        {
          label: 'Feedback',
          name: 'feedback',
          value: '',
          type: 'text',
          description: 'TextField',
          required: true,
        },
        //STARS
        {
          label: 'Qualidade tecnica',
          name: 'qualidade_tecnica',
          type: 'stars',
          value: 5,
          description: 'Star sem criterio',
          // rate: 0,
          required: true,
        },
        //DATE
        {
          label: 'Data do Jogo',
          name: 'data_do_jogo',
          value: '',
          type: 'date',
          description: 'dataaa',
          required: true,
        },
        //HORA
        {
          label: 'Hora do Jogo',
          name: 'hora_do_jogo',
          value: '',
          type: 'hour',
          description: 'hora do game',
          required: true,
        },
        //NUMERO
        {
          label: 'Minutos Jogados',
          name: 'minutos_jogados',
          value: '',
          type: 'number',
          description: 'hora do game',
          required: true,
        },
        //NUMEROS + e -
        {
          label: 'Cartoes vermelhos',
          name: 'cartoes_vermelhos',
          value: '',
          type: 'number2',
          description: 'hora do game',
          required: true,
        },
        //Radio com apenas uma opcao de escolha
        {
          label: 'Titular',
          name: 'titular',
          type: 'radio',
          value: '',
          options: [
            { value: 'sim', label: 'Sim' },
            { value: 'nao', label: 'Não' },
          ],
          description: 'hora do game',
          required: true,
        },
        //Radio com mais de uma opcao de escolha
        {
          label: 'Caracteristicas',
          name: 'caracteristicas',
          type: 'radio2',
          value: '',
          options: [
            { value: 'passador', label: 'Passador' },
            { value: 'definidor', label: 'definidor' },
            { value: 'palavra_composta', label: 'Palavra composta' },
          ],
          max_options: 2,
          description: 'hora do game',
          required: true,
        },
      ],
      context_fields: [
        {
          label: 'Qualidade tecnica',
          name: 'qualidade_tecnica',
          type: 'stars',
          value: '',
          rate: 0,
          required: true,
        },
        {
          label: 'Caracteristicas da Posição',
          name: 'caracteristicas_da_posicao',
          value: '',
          type: 'select_simples',
          options: [
            { value: 'passador', label: 'Passador' },
            { value: 'definidor', label: 'definidor' },
            { value: 'palavra_composta', label: 'Palavra composta' },
          ],
          required: true,
        },
        {
          label: 'Competições',
          name: 'competicoes',
          value: '',
          type: 'select_dinamico',
          endpoint: 'competitions',
          description: 'TESTE',
          required: true,
        },
        {
          label: 'Feedback',
          name: 'feedback',
          value: '',
          type: 'text',
          required: true,
        },
        {
          label: 'Titular',
          name: 'titular',
          type: 'boolean',
          value: '',
          required: true,
        },
        {
          label: 'Minutos Jogados',
          name: 'minutos_jogados',
          value: '',
          type: 'number',
          required: true,
        },
        {
          label: 'Cartoes vermelhos',
          name: 'cartoes_vermelhos',
          value: '',
          type: 'number2',
          required: true,
        },
      ],
    },
  },
];

export const clubs = [
  {
    id: 1098,
    shortName: 'Grêmio',
    fullName: 'Grêmio Foot-ball Porto Alegrense',
    img: ImgGremio,
    banner: GremioBanner,
    city: 'Porto Alegre',
    state: 'Rio Grande do Sul',
    country: 'Brasil',
    countryFlagImg: ImgBrazil,
    marketValue: '82,20 M €',
    marketValueSource: 'Transfermarket',
    website: 'http://www.gremio.com.br',
    wikipedia: 'https://pt.wikipedia.org/wiki/Gremio_Foot-Ball_Porto_Alegrense',
    instagram: 'https://www.instagram.com/gremio/',
    twitter: 'https://twitter.com/Gremio',
    youtube: 'https://www.youtube.com/channel/UCHKbUAiKHsWCCZrkDY_PZ8Q',
    foundation: '15/09/1903',
    phone: '+55 (51) 3218-2000',
    partners: 80.478,
    stadium: 'Arena do Grêmio',
    address: 'Av. Padre Leopoldo Brentano, 110/2100, Humaitá - Porto Alegre/RS - Brasil',
    CEP: '90250-590',
    competitionsAmount: 0,
    professionalCastAmount: 0,
    loanedAmount: 0,
    observationsAmount: 3,
    contactsAmount: 2,
    transfersAmount: 2,
    videosAmount: 4,
    newsAmount: 0,
    leaders: [
      {
        id: 1,
        name: 'Romildo Bolzan',
        role: 'Presidente',
        img: RomildoBolzan,
      },
      {
        id: 2,
        name: 'Duda Kroeff',
        role: 'Vice-Presidente de Futebol',
        img: DudaKroeff,
      },
      {
        id: 3,
        name: 'Alberto Guerra',
        role: 'Diretor de Futebol',
        img: AlbertoGuerra,
      },
      {
        id: 4,
        name: 'Klaus Kâmara',
        role: 'Diretor Executivo',
        img: KlausCamara,
      },
    ],
    latestCompetitions: [
      {
        season: '2018',
        competition: 'Brasileirão 2018',
        type: 'Nacional',
        position: 10,
        g1: 3,
        g2: 1,
        g3: 1,
        g4: 1,
        goalsScored: 2,
        goalsTaken: 2,
      },
      {
        season: '2018',
        competition: 'Gauchão 2018',
        type: 'Regional',
        position: 1,
        g1: 17,
        g2: 9,
        g3: 2,
        g4: 6,
        goalsScored: 31,
        goalsTaken: 19,
      },
      {
        season: '2018',
        competition: 'Recopa',
        type: 'Continental',
        position: 1,
        g1: 2,
        g2: 0,
        g3: 2,
        g4: 0,
        goalsScored: 1,
        goalsTaken: 1,
      },
    ],
    evaluations: [
      {
        data: '01/01/2018',
        author: 'Adriano Martins',
        client: 'Grêmio FBPA',
        clientImg: ImgGremio,
        scoreboard: '1:0',
        visitor: 'São Paulo',
        visitorImg: ImgSaoPaulo,
      },
      {
        data: '01/01/2018',
        author: 'Adriano Martins',
        client: 'Grêmio FBPA',
        clientImg: ImgGremio,
        scoreboard: '1:0',
        visitor: 'São Paulo',
        visitorImg: ImgSaoPaulo,
      },
    ],
    totalTransferAmount: '22,0 M €',
    transfers: [
      {
        athlete: 'André',
        athleteImg: ImgAvatar,
        athleteId: 2,
        origin: {
          clubImg: ImgSport,
          clubCountryImg: ImgBrazil,
          clubName: 'Sport',
        },
        destiny: {
          clubImg: ImgGremio,
          clubCountryImg: ImgBrazil,
          clubName: 'Grêmio',
        },
        date: '11/01/2018',
        type: 'Empréstimo',
        value: '2,50 M €',
      },
      {
        athlete: 'André',
        athleteImg: ImgAvatar,
        athleteId: 2,
        origin: {
          clubImg: ImgSport,
          clubCountryImg: ImgBrazil,
          clubName: 'Sport',
        },
        destiny: {
          clubImg: ImgGremio,
          clubCountryImg: ImgBrazil,
          clubName: 'Grêmio',
        },
        date: '11/01/2018',
        type: 'Empréstimo',
        value: '2,50 M €',
      },
      {
        athlete: 'André',
        athleteImg: ImgAvatar,
        athleteId: 2,
        origin: {
          clubImg: ImgSport,
          clubCountryImg: ImgBrazil,
          clubName: 'Sport',
        },
        destiny: {
          clubImg: ImgGremio,
          clubCountryImg: ImgBrazil,
          clubName: 'Grêmio',
        },
        date: '11/01/2018',
        type: 'Empréstimo',
        value: '2,50 M €',
      },
    ],
    videos: [
      {
        url: 'https://www.youtube.com/watch?v=TEYFpK94SsI',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios ● Goles y Jugadas ● Grêmio 2017 ● HD 1080p',
      },
      {
        url: 'https://www.youtube.com/watch?v=J2R-tr4by1s',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios ☆ Goals ☆ Borussia Dortmund',
      },
      {
        url: 'https://www.youtube.com/watch?v=h5PTbKKCnW0',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios || Goles, Lujos y Jugadas || Bienvenido a Casa || Argentinos JRS',
      },
      {
        url: 'https://www.youtube.com/watch?v=TEYFpK94SsI?',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios ● Goles y Jugadas ● Grêmio 2017 ● HD 1080p',
      },
    ],
    news: [
      {
        id: 1,
        date: '10/02/2018',
        title: 'Lucas Barrios y una sensible lesión para Argentinos',
        sourceName: 'TyC Sports',
        sourceLink: '#',
      },
      {
        id: 2,
        date: '08/02/2018',
        title: 'Lucas Barrios: "El fútbol alemán me cambió la cabeza"',
        sourceName: 'TyC Sports',
        sourceLink: '#',
      },
      {
        id: 3,
        date: '03/02/2018',
        title: 'Ex-Grêmio e Palmeiras dá show e ajuda time a "decolar" na Superliga Argentina',
        sourceName: 'Fox Sports',
        sourceLink: '#',
      },
    ],
  },
];

export const professionalCast = [
  {
    teamId: 1098,
    data: [
      {
        athleteId: 1,
        athleteName: 'Fernando Henrique',
        athleteImg: ImgAvatar,
        teamId: 1098,
        clubName: 'Grêmio',
        clubImg: ImgGremio,
        athleteAge: "'01 (16)",
        athleteRole: 'Meio-campo',
        athleteNationalityImg: ImgBrazil,
        endOfContract: '31/07/2018',
        cedingClubImg: ImgABC,
        cedingClubName: 'ABC',
        cedingteamId: 2,
        cedingClubContract: '31/05/2020',
        note: '3',
        rate: 2,
        targeting: '',
        priority: '',
      },
      {
        athleteId: 2,
        athleteName: 'Fernando Henrique',
        athleteImg: ImgAvatar,
        teamId: 1098,
        clubName: 'Grêmio',
        clubImg: ImgGremio,
        athleteAge: "'01 (16)",
        athleteRole: 'Meio-campo',
        athleteNationalityImg: ImgBrazil,
        endOfContract: '31/07/2018',
        cedingClubImg: ImgABC,
        cedingClubName: 'ABC',
        cedingteamId: 2,
        cedingClubContract: '31/05/2020',
        note: '3',
        rate: 3,
        targeting: 'monitorar',
        priority: 'media',
      },
      {
        athleteId: 3,
        athleteName: 'Fernando Henrique',
        athleteImg: ImgAvatar,
        teamId: 1098,
        clubName: 'Grêmio',
        clubImg: ImgGremio,
        athleteAge: "'01 (16)",
        athleteRole: 'Meio-campo',
        athleteNationalityImg: ImgBrazil,
        endOfContract: '31/07/2018',
        cedingClubImg: ImgABC,
        cedingClubName: 'ABC',
        cedingteamId: 2,
        cedingClubContract: '31/05/2020',
        note: '3',
        rate: 4.5,
        targeting: 'contratar',
        priority: 'alta',
      },
    ],
  },
];

export const loaned = [
  {
    teamId: 1098,
    data: [
      {
        athleteId: 1,
        athleteName: 'Fernando Henrique',
        athleteImg: ImgAvatar,
        teamId: 1098,
        clubName: 'Grêmio',
        clubImg: ImgGremio,
        athleteAge: "'01 (16)",
        athleteRole: 'Meio-campo',
        athleteNationalityImg: ImgBrazil,
        endOfContract: '31/07/2018',
        loanClubImg: ImgABC,
        loanClubName: 'ABC',
        loanteamId: 2,
        loanClubContract: '31/05/2020',
        note: '3',
        rate: 2,
        targeting: '',
        priority: '',
      },
      {
        athleteId: 2,
        athleteName: 'Fernando Henrique',
        athleteImg: ImgAvatar,
        teamId: 1098,
        clubName: 'Grêmio',
        clubImg: ImgGremio,
        athleteAge: "'01 (16)",
        athleteRole: 'Meio-campo',
        athleteNationalityImg: ImgBrazil,
        endOfContract: '31/07/2018',
        loanClubImg: ImgABC,
        loanClubName: 'ABC',
        loanteamId: 2,
        loanClubContract: '31/05/2020',
        note: '3',
        rate: 2,
        targeting: '',
        priority: '',
      },
      {
        athleteId: 3,
        athleteName: 'Fernando Henrique',
        athleteImg: ImgAvatar,
        teamId: 1098,
        clubName: 'Grêmio',
        clubImg: ImgGremio,
        athleteAge: "'01 (16)",
        athleteRole: 'Meio-campo',
        athleteNationalityImg: ImgBrazil,
        endOfContract: '31/07/2018',
        loanClubImg: ImgABC,
        loanClubName: 'ABC',
        loanteamId: 2,
        loanClubContract: '31/05/2020',
        note: '3',
        rate: 2,
        targeting: '',
        priority: '',
      },
    ],
  },
];

export const evaluations = [
  {
    teamId: 1098,
    performed: [
      {
        date: '01/04/2018',
        competition: 'Brasileirão 2018 - Série A',
        client: 'Grêmio FBPA',
        clientImg: ImgGremio,
        scoreboard: '1:0',
        visitor: 'São Paulo',
        visitorImg: ImgSaoPaulo,
        athletes: 6,
        thumbsUp: 1,
        views: 3,
        author: 'Adriano Martins',
      },
      {
        date: '01/04/2018',
        competition: 'Brasileirão 2018 - Série A',
        client: 'Grêmio FBPA',
        clientImg: ImgGremio,
        scoreboard: '1:0',
        visitor: 'São Paulo',
        visitorImg: ImgSaoPaulo,
        athletes: 22,
        thumbsUp: 2,
        views: 4,
        author: 'Adriano Martins',
      },
      {
        date: '01/04/2018',
        competition: 'Brasileirão 2018 - Série A',
        client: 'Grêmio FBPA',
        clientImg: ImgGremio,
        scoreboard: '1:0',
        visitor: 'São Paulo',
        visitorImg: ImgSaoPaulo,
        athletes: 24,
        thumbsUp: 1,
        views: 2,
        author: 'Adriano Martins',
      },
    ],
  },
];

export const contacts = [
  {
    teamId: 1098,
    data: [
      {
        name: 'Klaus Kâmara',
        category: 'Profissional',
        role: 'Diretor Executivo',
        phone: '(32) 8441-0797',
        whatsapp: '(32) 8867-2202',
        email: 'andre.babo@bol.com.br',
      },
      {
        name: 'Romildo Bolzan',
        category: 'Profissional / Base',
        role: 'Presidente',
        phone: '(32) 99991-6635',
        whatsapp: '(32) 99991-6635',
        email: '-',
      },
    ],
  },
];

export const videos = [
  {
    teamId: 1098,
    data: [
      {
        url: 'https://www.youtube.com/watch?v=TEYFpK94SsI',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios ● Goles y Jugadas ● Grêmio 2017 ● HD 1080p',
      },
      {
        url: 'https://www.youtube.com/watch?v=J2R-tr4by1s',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios ☆ Goals ☆ Borussia Dortmund',
      },
      {
        url: 'https://www.youtube.com/watch?v=h5PTbKKCnW0',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios || Goles, Lujos y Jugadas || Bienvenido a Casa || Argentinos JRS',
      },
      {
        url: 'https://www.youtube.com/watch?v=TEYFpK94SsI?',
        mediaProviderImg: ImgYoutube,
        title: 'Lucas Barrios ● Goles y Jugadas ● Grêmio 2017 ● HD 1080p',
      },
    ],
  },
];

export const news = [
  {
    teamId: 1098,
    data: [
      {
        id: '1',
        title: 'Lucas Barrios y una sensible lesión para Argentinos',
        subtitle:
          'La estrella del Bicho debió abandonar el duelo ante Tigre en Victoria con una dolencia en la cara posterior del muslo derecho y esta mañana el club confirmó la lesióin a través de las redes sociales. ¿Llega al partido con Boca?',
        date: '10/02/2018',
        sourceName: 'TyC Sports',
        sourceLink: '#',
        img: NewsImg,
        body:
          '<p>Lucas Barrios, el refuerzo más resonante de Argentinos Juniors y una de las mejores incorporaciones del último mercado de pases, sufrió una lesióin que lo obligó a dejar el campo de juego en el encuntro frente a Tigre en Victoria y esta mañana se oficializó el parte médico.</p><p>Técnicamente, el experimentado atacante padeció una "lesion fibrilar en el tercio proximal del bíceps femoral en el miembro inferior derecho", es decir un desgarro que lo obligó a ser reemplazado por Nicolás González a los 21 minutos del primer tiempo y sentarse en el banco con hielo en la zona afectada. Por esta lesióin, Barrios estaría alrededor de 21 días afuera de las canchas por lo que se perderá los duelos ante Atlético Tucumán y Talleres, y habrá que evaluar su mejoría para saber si llega al encruentro ante Boca. Malas noticias para Alfredo Berti y toda La Paternal.</p>',
      },
      {
        id: '2',
        title: 'Lucas Barrios: "El fúbol alemán me cambió la cabeza"',
        subtitle:
          'La estrella del Bicho debió abandonar el duelo ante Tigre en Victoria con una dolencia en la cara posterior del muslo derecho y esta mañana el club confirmó la lesióin a través de las redes sociales. ¿Llega al partido con Boca?',
        date: '08/02/2018',
        sourceName: 'TyC Sports',
        sourceLink: '#',
        img: NewsImg,
        body:
          '<p>Lucas Barrios, el refuerzo más resonante de Argentinos Juniors y una de las mejores incorporaciones del último mercado de pases, sufrió una lesióin que lo obligó a dejar el campo de juego en el encuntro frente a Tigre en Victoria y esta mañana se oficializó el parte médico.</p><p>Técnicamente, el experimentado atacante padeció una "lesion fibrilar en el tercio proximal del bíceps femoral en el miembro inferior derecho", es decir un desgarro que lo obligó a ser reemplazado por Nicolás González a los 21 minutos del primer tiempo y sentarse en el banco con hielo en la zona afectada. Por esta lesióin, Barrios estaría alrededor de 21 días afuera de las canchas por lo que se perderá los duelos ante Atlético Tucumán y Talleres, y habrá que evaluar su mejoría para saber si llega al encruentro ante Boca. Malas noticias para Alfredo Berti y toda La Paternal.</p>',
      },
      {
        id: '3',
        title: 'Ex-Grêmio e Palmeiras dá show e ajuda time a "decolar" na Superliga Argentina',
        subtitle:
          'La estrella del Bicho debió abandonar el duelo ante Tigre en Victoria con una dolencia en la cara posterior del muslo derecho y esta mañana el club confirmó la lesióin a través de las redes sociales. ¿Llega al partido con Boca?',
        date: '03/02/2018',
        sourceName: 'Fox Sports',
        sourceLink: '#',
        img: NewsImg,
        body:
          '<p>Lucas Barrios, el refuerzo más resonante de Argentinos Juniors y una de las mejores incorporaciones del último mercado de pases, sufrió una lesióin que lo obligó a dejar el campo de juego en el encuntro frente a Tigre en Victoria y esta mañana se oficializó el parte médico.</p><p>Técnicamente, el experimentado atacante padeció una "lesion fibrilar en el tercio proximal del bíceps femoral en el miembro inferior derecho", es decir un desgarro que lo obligó a ser reemplazado por Nicolás González a los 21 minutos del primer tiempo y sentarse en el banco con hielo en la zona afectada. Por esta lesióin, Barrios estaría alrededor de 21 días afuera de las canchas por lo que se perderá los duelos ante Atlético Tucumán y Talleres, y habrá que evaluar su mejoría para saber si llega al encruentro ante Boca. Malas noticias para Alfredo Berti y toda La Paternal.</p>',
      },
    ],
  },
];

export const reportCheckboxes = [
  {
    value: 'generalFeatures',
  },
  {
    value: 'negotiation',
  },
  {
    value: 'images',
  },
  {
    value: 'evaluations',
  },
  {
    value: 'historic',
  },
  {
    value: 'career',
  },
];

export const targetingOptions = [
  {
    label: 'Contratar',
    value: 'Contratar',
  },
];

export const targetingOptionsCB = [
  {
    label: 'CONTRATAR',
    value: 'CONTRATAR',
  },
  {
    label: 'MONITORAR',
    value: 'MONITORAR',
  },
  {
    label: 'DESCARTAR',
    value: 'DESCARTAR',
  },
];

export const priorityOptionsCB = [
  {
    label: 'ALTA',
    value: 'ALTA',
  },
  {
    label: 'MEDIA',
    value: 'MEDIA',
  },
  {
    label: 'BAIXA',
    value: 'BAIXA',
  },
];

export const radioAdvancedSearch = {
  gender: [
    {
      value: 'female',
    },
    {
      value: 'male',
    },
  ],
  custom_athletes: [
    {
      value: 'custom',
    },
    {
      value: 'normal',
    },
  ],
  custom_athletes2: [
    {
      value: 'normal',
    },
  ],
};

export const checkboxesAdvancedSearch = {
  ad_type: [
    {
      value: 'active',
      label: 'Ativo',
    },
    {
      value: 'inactive',
      label: 'Inativo',
    },
  ],
  negotiation_type: [
    {
      value: 'SALE',
      label: 'Venda',
    },
    {
      value: 'LOAN',
      label: 'Empréstimo',
    },
  ],
  profile: [
    {
      value: 'atletas-profissionais',
    },
    {
      value: 'atletas-amadores',
    },
  ],
  contract: [
    {
      value: 'com-contrato',
    },
    {
      value: 'sem-contrato',
    },
  ],
  monitoredAthlete: [
    {
      value: 'monitorado',
      label: 'Sim',
    },
    {
      value: 'nao-monitorado',
      label: 'Não',
    },
  ],
  division: [
    {
      value: '1',
      label: 'Série A',
    },
    {
      value: '2',
      label: 'Série B',
    },
    {
      value: '3',
      label: 'Série C',
    },
    {
      value: '4',
      label: 'Série D',
    },
    {
      value: '',
      label: 'Todos',
    },
  ],
  newDivision: [
    {
      id: 'UmDivisao',
      value: '1',
      label: '1ª Divisão',
    },
    {
      id: 'DoisDivisao',
      value: '2',
      label: '2ª Divisão',
    },
    {
      id: 'TresDivisao',
      value: '3',
      label: '3ª Divisão',
    },
    {
      id: 'QuatroDivisao',
      value: '4',
      label: '4ª Divisão',
    },
    {
      id: 'SemDivisao',
      value: '0',
      label: 'Sem divisão',
    },
  ],
  europeanPassport: [
    {
      value: 'possui-passaporte',
      label: 'Sim',
    },
    {
      value: 'nao-possui-passaporte',
      label: 'Não',
    },
  ],
};

export const divisionOptions = [
  {
    label: '1a Divisão - Nacional',
    value: '1',
  },
  {
    label: '2a Divisão - Nacional',
    value: '2',
  },
  {
    label: '3a Divisão - Nacional',
    value: '3',
  },
  {
    label: '4a Divisão - Nacional',
    value: '4',
  },
];

export const criteriaOptions = [
  {
    // label: 'Nenhum critério',
    value: '',
  },
  {
    // label: 'Por temporada',
    value: 'season',
  },
  {
    // label: 'Por competição',
    value: 'competition',
  },
  {
    // label: 'Por clube',
    value: 'team',
  },
];

export const categoryOptions = [
  { value: 'profissional' },
  { value: 'amador' },
  { value: 'sub23' },
  { value: 'sub22' },
  { value: 'sub21' },
  { value: 'sub20' },
  { value: 'sub19' },
  { value: 'sub18' },
  { value: 'sub17' },
  { value: 'sub16' },
  { value: 'sub15' },
  { value: 'sub14' },
  { value: 'sub13' },
  { value: 'sub12' },
  { value: 'sub11' },
  { value: 'sub10' },
  { value: 'sub9' },
];

export const brazilianStates = [
  {
    label: 'Acre',
    value: 'AC',
  },
  {
    label: 'Alagoas',
    value: 'AL',
  },
  {
    label: 'Amapá',
    value: 'AP',
  },
  {
    label: 'Amazonas',
    value: 'AM',
  },
  {
    label: 'Bahia',
    value: 'BA',
  },
  {
    label: 'Ceará',
    value: 'CE',
  },
  {
    label: 'Distrito Federal',
    value: 'DF',
  },
  {
    label: 'Espírito Santo',
    value: 'ES',
  },
  {
    label: 'Goiás',
    value: 'GO',
  },
  {
    label: 'Maranhão',
    value: 'MA',
  },
  {
    label: 'Mato Grosso',
    value: 'MT',
  },
  {
    label: 'Mato Grosso do Sul',
    value: 'MS',
  },
  {
    label: 'Minas Gerais',
    value: 'MG',
  },
  {
    label: 'Pará',
    value: 'PA',
  },
  {
    label: 'Paraíba',
    value: 'PB',
  },
  {
    label: 'Paraná',
    value: 'PR',
  },
  {
    label: 'Pernambuco',
    value: 'PE',
  },
  {
    label: 'Piauí',
    value: 'PI',
  },
  {
    label: 'Rio de Janeiro',
    value: 'RJ',
  },
  {
    label: 'Rio Grande do Norte',
    value: 'RN',
  },
  {
    label: 'Rio Grande do Sul',
    value: 'RS',
  },
  {
    label: 'Rondônia',
    value: 'RO',
  },
  {
    label: 'Roraima',
    value: 'RR',
  },
  {
    label: 'Santa Catarina',
    value: 'SC',
  },
  {
    label: 'São Paulo',
    value: 'SP',
  },
  {
    label: 'Sergipe',
    value: 'SE',
  },
  {
    label: 'Tocantins',
    value: 'TO',
  },
];

export const positionOptions = [
  {
    value: 'Atacante',
    label: 'Atacante',
  },
  {
    value: 'Ataque',
    label: 'Ataque',
  },
  {
    value: 'Centroavante',
    label: 'Centroavante',
  },
  {
    value: 'Defesa',
    label: 'Defesa',
  },
  {
    value: 'Goleiro',
    label: 'Goleiro',
  },
  {
    value: 'LateralDireito',
    label: 'Lateral Direito',
  },
  {
    value: 'LateralEsquerdo',
    label: 'Lateral Esquerdo',
  },
  {
    value: 'Meia',
    label: 'Meia',
  },
  {
    value: 'MeiaAtacante',
    label: 'Meia-atacante',
  },
  {
    value: 'MeioCampo',
    label: 'Meio-campo',
  },
  {
    value: 'Volante',
    label: 'Volante',
  },
  {
    value: 'Zagueiro',
    label: 'Zagueiro',
  },
];

export const gamePositionOptions = [
  {
    value: 'Atacante',
    label: 'Atacante',
  },
  {
    value: 'Ataque',
    label: 'Ataque',
  },
  {
    value: 'Centroavante',
    label: 'Centroavante',
  },
  {
    value: 'ExtremoDireito',
    label: 'ExtremoDireito',
  },
  {
    value: 'ExtremoEsquerdo',
    label: 'ExtremoEsquerdo',
  },
  {
    value: 'Defesa',
    label: 'Defesa',
  },
  {
    value: 'Goleiro',
    label: 'Goleiro',
  },
  {
    value: 'LateralDireito',
    label: 'Lateral Direito',
  },
  {
    value: 'LateralEsquerdo',
    label: 'Lateral Esquerdo',
  },
  {
    value: 'Meia',
    label: 'Meia',
  },
  {
    value: 'MeiaAtacante',
    label: 'Meia-atacante',
  },
  {
    value: 'MeioCampo',
    label: 'Meio-campo',
  },
  {
    value: 'Volante',
    label: 'Volante',
  },
  {
    value: 'Zagueiro',
    label: 'Zagueiro',
  },
];

export const preferentialFoot = [
  {
    value: 'Ambos',
  },
  {
    value: 'Direito',
  },
  {
    value: 'Esquerdo',
  },
];

export const performance = [
  {
    value: 'ABAIXO',
  },
  {
    value: 'MEDIO',
  },
  {
    value: 'ACIMA',
  },
  {
    value: 'MUITO_ACIMA',
  },
];

export const statureOptions = [{ value: 'alto' }, { value: 'medio' }, { value: 'baixo' }];

export const getPreferentialFootWithLabel = t => {
  return preferentialFoot.map(foot => {
    return {
      value: foot.value,
      label: t(`preferentialFoot.${foot.value}`),
    };
  });
};

export const getPerformanceWithLabel = t => {
  return performance.map(foot => {
    return {
      value: foot.value,
      label: t(`performance.${foot.value}`),
    };
  });
};

export const fileTypeOptions = ['pdf', 'video_link'];

export const nationalitiesOptions = [
  { value: 'brazilian' },
  { value: 'afghan' },
  { value: 'albanian' },
  { value: 'algerian' },
  { value: 'american' },
  { value: 'andorran' },
  { value: 'angolan' },
  { value: 'argentine' },
  { value: 'armenian' },
  { value: 'australian' },
  { value: 'austrian' },
  { value: 'azerbaijani' },
  { value: 'bahamian' },
  { value: 'bahraini' },
  { value: 'bangladeshi' },
  { value: 'barbadian' },
  { value: 'belarusian' },
  { value: 'belgian' },
  { value: 'belizean' },
  { value: 'beninese' },
  { value: 'bhutanese' },
  { value: 'bolivian' },
  { value: 'bosnian' },
  { value: 'british' },
  { value: 'bruneian' },
  { value: 'bulgarian' },
  { value: 'burkinabe' },
  { value: 'burundian' },
  { value: 'cambodian' },
  { value: 'cameroonian' },
  { value: 'canadian' },
  { value: 'cape_verdean' },
  { value: 'central_african' },
  { value: 'chadian' },
  { value: 'chilean' },
  { value: 'chinese' },
  { value: 'colombian' },
  { value: 'comoran' },
  { value: 'congolese' },
  { value: 'costarican' },
  { value: 'croatian' },
  { value: 'cuban' },
  { value: 'cypriot' },
  { value: 'czech' },
  { value: 'danish' },
  { value: 'djiboutian' },
  { value: 'dominican' },
  { value: 'dutch' },
  { value: 'east_timorese' },
  { value: 'ecuadorian' },
  { value: 'egyptian' },
  { value: 'emirian' },
  { value: 'equatorial_guinean' },
  { value: 'eritrean' },
  { value: 'estonian' },
  { value: 'ethiopian' },
  { value: 'fijian' },
  { value: 'filipino' },
  { value: 'finnish' },
  { value: 'french' },
  { value: 'gabonese' },
  { value: 'gambian' },
  { value: 'georgian' },
  { value: 'german' },
  { value: 'ghanaian' },
  { value: 'greek' },
  { value: 'grenadian' },
  { value: 'guatemalan' },
  { value: 'guinea_bissauan' },
  { value: 'guinean' },
  { value: 'guyanese' },
  { value: 'haitian' },
  { value: 'herzegovinian' },
  { value: 'honduran' },
  { value: 'hungarian' },
  { value: 'icelander' },
  { value: 'indian' },
  { value: 'indonesian' },
  { value: 'iranian' },
  { value: 'iraqi' },
  { value: 'irish' },
  { value: 'israeli' },
  { value: 'italian' },
  { value: 'ivorian' },
  { value: 'jamaican' },
  { value: 'japanese' },
  { value: 'jordanian' },
  { value: 'kazakhstani' },
  { value: 'kenyan' },
  { value: 'kittitian_and_nevisian' },
  { value: 'kuwaiti' },
  { value: 'kyrgyz' },
  { value: 'laotian' },
  { value: 'latvian' },
  { value: 'lebanese' },
  { value: 'liberian' },
  { value: 'libyan' },
  { value: 'liechtensteiner' },
  { value: 'lithuanian' },
  { value: 'luxembourger' },
  { value: 'macedonian' },
  { value: 'malagasy' },
  { value: 'malawian' },
  { value: 'malaysian' },
  { value: 'maldivan' },
  { value: 'malian' },
  { value: 'maltese' },
  { value: 'marshallese' },
  { value: 'mauritanian' },
  { value: 'mauritian' },
  { value: 'mexican' },
  { value: 'micronesian' },
  { value: 'moldovan' },
  { value: 'moldovan' },
  { value: 'monacan' },
  { value: 'mongolian' },
  { value: 'moroccan' },
  { value: 'mosotho' },
  { value: 'motswana' },
  { value: 'mozambican' },
  { value: 'namibian' },
  { value: 'nauruan' },
  { value: 'nepalese' },
  { value: 'new_zealander' },
  { value: 'nicaraguan' },
  { value: 'nigerien' },
  { value: 'nigerian' },
  { value: 'north_korean' },
  { value: 'northern_irish' },
  { value: 'norwegian' },
  { value: 'omani' },
  { value: 'pakistani' },
  { value: 'palauan' },
  { value: 'panamanian' },
  { value: 'papua_new_guinean' },
  { value: 'paraguayan' },
  { value: 'peruvian' },
  { value: 'polish' },
  { value: 'portuguese' },
  { value: 'qatari' },
  { value: 'romanian' },
  { value: 'russian' },
  { value: 'rwandan' },
  { value: 'saint_lucian' },
  { value: 'salvadoran' },
  { value: 'samoan' },
  { value: 'san_marinese' },
  { value: 'sao_tomean' },
  { value: 'saudi' },
  { value: 'senegalese' },
  { value: 'serbian' },
  { value: 'seychellois' },
  { value: 'sierra_leonean' },
  { value: 'singaporean' },
  { value: 'slovak' },
  { value: 'slovenian' },
  { value: 'solomon_islander' },
  { value: 'somali' },
  { value: 'south_african' },
  { value: 'south_korean' },
  { value: 'spanish' },
  { value: 'sri_lankan' },
  { value: 'sudanese' },
  { value: 'surinamer' },
  { value: 'swazi' },
  { value: 'swedish' },
  { value: 'swiss' },
  { value: 'syrian' },
  { value: 'taiwanese' },
  { value: 'tajik' },
  { value: 'tanzanian' },
  { value: 'thai' },
  { value: 'togolese' },
  { value: 'tongan' },
  { value: 'trinidadian_or_tobagonian' },
  { value: 'tunisian' },
  { value: 'turkish' },
  { value: 'tuvaluan' },
  { value: 'ugandan' },
  { value: 'ukrainian' },
  { value: 'uruguayan' },
  { value: 'uzbekistani' },
  { value: 'venezuelan' },
  { value: 'vietnamese' },
  { value: 'yemenite' },
  { value: 'zambian' },
  { value: 'zimbabwean' },
];
