import React, { useState } from 'react';

export default function ToolTip({ message, children, position = 'bottom', className = '' }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getPositionStyles = () => {
    switch (position) {
      case 'top':
        return {
          tooltip: {
            bottom: '105%',
            left: '50%',
            transform: 'translateX(-50%)',
          },
          arrow: {
            top: '90%',
            left: '50%',
            transform: 'translateX(-50%) rotate(45deg)',
          },
        };
      case 'bottom':
      default:
        return {
          tooltip: {
            top: '55px',
            left: '50%',
            transform: 'translateX(-50%)',
            height: '10px',
            minWidth: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          arrow: {
            bottom: '80%',
            left: '44%%',
            transform: 'rotate(45deg)',
          },
        };
    }
  };

  const { tooltip, arrow } = getPositionStyles();

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div
          style={{
            position: 'absolute',

            backgroundColor: '#120033',
            color: 'white',
            fontSize: '14px',
            fontWeight: '600',
            padding: '8px',
            borderRadius: '8px',
            zIndex: '9999',
            ...tooltip,
          }}
          className={className}
        >
          <div
            style={{
              position: 'absolute',
              backgroundColor: '#120033',
              height: '10px',
              width: '10px',
              ...arrow,
            }}
          />
          {message}
        </div>
      )}
      {children}
    </div>
  );
}
