import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
//import "../../translations/i18n"

import styles from './ReportAppBar.module.scss';
import Logo from 'assets/footlink-color-logo.svg';

export default function ReportAppBar({ brandTag, reportData }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);
  return (
    <div className={styles.container}>
      <div className={styles.leftItems}>
        <img draggable="false" className={styles.brandLogo} src={user?.organization?.clubImg} alt="Logo Footlink" />
        <div className={styles.relatDetails}>
          {reportData?.name ? (
            <h1>Relatório {reportData?.name?.length > 30 ? `${reportData.name.slice(0, 30)}...` : reportData?.name}</h1>
          ) : (
            <h1>{t('report.relat')}</h1>
          )}
          <span>
            {t('report.gene_for')}
            {user.name}
          </span>
          <span>{moment(new Date()).format('DD/MM/YYYY')}</span>
        </div>
      </div>
      {brandTag && (
        <div className={styles.centerItems}>
          <div className={styles.brandTag}>
            <span>
              {t('report.gene_in')}
              <span className={styles.darkPurple9}>rededofutebol.com.br</span>
            </span>
            {reportData?.project && (
              <>
                <br />
                <span>
                  {t('report.project')}
                  <span className={styles.darkPurple9}>{reportData.project}</span>
                </span>
              </>
            )}
          </div>
        </div>
      )}
      <div className={styles.rightItems}>
        <img draggable="false" className={styles.brandLogo} src={Logo} alt="Logo Footlink" />
      </div>
    </div>
  );
}

ReportAppBar.defaultProps = {
  brandTag: false,
};
ReportAppBar.propTypes = {
  brandTag: PropTypes.bool,
};
