import download from 'downloadjs';
import axios from 'api';
import Endpoints from 'endpoints';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET_REPORTS: 'reports/GET',
  GET_REPORTS_SUCCESS: 'reports/GET_SUCCESS',
  GET_REPORTS_ERROR: 'reports/GET_ERROR',
  DELETE_REPORTS: 'reports/DELETE',
  DELETE_REPORTS_SUCCESS: 'reports/DELETE_SUCCESS',
  DELETE_REPORTS_ERROR: 'reports/DELETE_ERROR',
  GET_REPORT: 'report/GET',
  GET_REPORT_SUCCESS: 'report/GET_SUCCESS',
  GET_REPORT_ERROR: 'report/GET_ERROR',
  GET_REPORT_PDF: 'report/GET_PDF',
  GET_REPORT_PDF_SUCCESS: 'report/GET_SUCCESS_PDF',
  GET_REPORT_PDF_ERROR: 'report/GET_ERROR_PDF',
  RESET_REPORT_PDF: 'report/RESET_PDF',
  CREATE_REPORT: 'report/CREATE',
  CREATE_REPORT_SUCCESS: 'report/CREATE_SUCCESS',
  CREATE_REPORT_ERROR: 'report/CREATE_ERROR',
  UPDATE_REPORT: 'report/UPDATE',
  UPDATE_REPORT_SUCCESS: 'report/UPDATE_SUCCESS',
  UPDATE_REPORT_ERROR: 'report/UPDATE_ERROR',
};

const initialState = {
  reports: {
    data: [],
    loading: false,
    error: null,
    hasNext: false,
    hasPrevious: false,
    count: 0,
  },
  report: {
    data: null,
    loading: false,
    error: null,
    updating: false,
  },
  pdfGeneration: {
    loading: false,
    generated: false,
    error: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_REPORTS:
      return {
        ...state,
        reports: {
          ...initialState.reports,
          loading: true,
        },
      };
    case actionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          loading: false,
          data: action.payload.data,
          error: null,
          count: action.payload.count,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
        },
      };
    case actionTypes.GET_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...initialState.reports,
          error: action.payload.error,
        },
      };
    case actionTypes.CREATE_REPORT:
      return {
        ...state,
        report: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case actionTypes.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case actionTypes.CREATE_REPORT_ERROR:
      return {
        ...state,
        report: {
          data: null,
          loading: false,
          error: action.payload.error,
        },
      };

    case actionTypes.GET_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: !!action?.payload?.loading,
        },
      };
    case actionTypes.GET_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          data: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case actionTypes.GET_REPORT_ERROR:
      return {
        ...state,
        report: {
          ...initialState.report,
          error: action.payload.error,
        },
      };

    case actionTypes.GET_REPORT_PDF:
      return {
        ...state,
        pdfGeneration: {
          loading: true,
          generated: false,
          error: null,
        },
      };
    case actionTypes.GET_REPORT_PDF_SUCCESS:
      return {
        ...state,
        pdfGeneration: {
          loading: false,
          generated: true,
          error: null,
        },
      };
    case actionTypes.GET_REPORT_PDF_ERROR:
      return {
        ...state,
        pdfGeneration: {
          loading: false,
          generated: false,
          error: action.payload.error,
        },
      };
    case actionTypes.RESET_REPORT_PDF:
      return {
        ...state,
        pdfGeneration: initialState.pdfGeneration,
      };
    case actionTypes.UPDATE_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          updating: true,
        },
      };
    case actionTypes.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          error: null,
          updating: false,
        },
      };
    case actionTypes.UPDATE_REPORT_ERROR:
      return {
        ...state,
        report: {
          ...state.report,
          updating: false,
          error: action.payload.error,
        },
      };
    case actionTypes.DELETE_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      };
    case actionTypes.DELETE_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
        },
      };
    default:
      return state;
  }
}

// GET
export const getReports = filters => async dispatch => {
  dispatch({ type: actionTypes.GET_REPORTS });

  const url = Endpoints.reports;

  try {
    const queryString = stringifyFilters(filters);
    const { data } = await axios.get(`${url}${queryString}`);

    dispatch({
      type: actionTypes.GET_REPORTS_SUCCESS,
      payload: {
        data: data.results,
        count: data.count,
        hasNext: !!data.next,
        hasPrevious: !!data.previous,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_REPORTS_ERROR,
      payload: { error: 'erros.text33' },
    });
  }
};

export const getReportIdFromShadowTeam = async (projectId, shadowTeamId, athletesIds) => {
  try {
    const url = Endpoints.reports;
    const { data } = await axios.get(`${Endpoints.reports}/?project=${projectId}&shadow_team=${shadowTeamId}`);
    if ((data?.results ?? []).length > 0) {
      const reportId = data.results[0].id;
      const reportAthletesIds = data.results[0].athletes.ids;
      const newAthletesIds = [];
      const deletedAthletesIds = [];
      athletesIds.forEach(aId => !reportAthletesIds.includes(aId) && newAthletesIds.push(aId));
      reportAthletesIds.forEach(raId => !athletesIds.includes(raId) && deletedAthletesIds.push(raId));

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await Promise.all([
        ...deletedAthletesIds.map(id => axios.delete(`${url}/${reportId}/memberships/${id}`)),
        ...newAthletesIds.map(id => {
          const formData = new FormData();
          formData.append('athlete', id);
          return axios.post(`${url}/${reportId}/memberships`, formData, config);
        }),
      ]);

      return reportId;
    }
  } catch (err) {
    return -1;
  }
};

export const updateMembership = async (reportId, athleteId) => {
  try {
    await axios.put(`${Endpoints.reports}/${reportId}/memberships/${athleteId}`);
    return true;
  } catch (err) {}
  return false;
};

export const deleteMembership = async (reportId, athleteId) => {
  try {
    await axios.delete(`${Endpoints.reports}/${reportId}/memberships/${athleteId}`);
    return true;
  } catch (err) {}
  return false;
};

const _getReport = async id => {
  const { data } = await axios.get(`${Endpoints.reports}/${id}`);
  const projectId = data?.project?.id;
  const shadowTeamId = data?.shadow_team?.id;
  const shadowTeamPositionName = data?.shadow_team_positions;
  if (projectId && shadowTeamId) {
    data.shadow_team_positions_selected = shadowTeamPositionName ? shadowTeamPositionName.split(',') : [];
    const shadowTeamPosition = await axios.get(
      `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/positions/`
    );
    const shadowTeamPositionResult = shadowTeamPosition?.data?.results ?? [];
    if (shadowTeamPositionResult.length > 0) {
      data.shadow_team_positions = shadowTeamPositionResult;
      const shadowTeamAthletes = await axios.get(
        `${Endpoints.projects}/${projectId}/shadow_teams/${shadowTeamId}/athletes/`
      );
      if (shadowTeamAthletes?.data?.results) {
        data.shadow_team_athletes = shadowTeamAthletes.data.results;
        data.shadow_team_athletes.sort(function(a, b) {
          const orderA = a.positionOrder ?? 0;
          const orderB = b.positionOrder ?? 0;
          return orderA - orderB;
        });
      }
    }
  }

  return data;
};

export const getReport = (id, loading = true) => async dispatch => {
  dispatch({ type: actionTypes.GET_REPORT, payload: { loading } });
  try {
    // const queryString = stringifyFilters(filters);
    const data = await _getReport(id);
    if (data.athletes) {
      data.athletes.forEach(ath => {
        if (ath.custom_athlete) {
          ath.athlete = ath.custom_athlete;
          ath.custom_athlete = true;
        } else {
          ath.custom_athlete = false;
        }
      });
    }
    dispatch({
      type: actionTypes.GET_REPORT_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_REPORT_ERROR,
      payload: { error: 'erros.text34' },
    });
  }
};

export const getReportPDF = id => async dispatch => {
  const url = Endpoints.reports;

  dispatch({ type: actionTypes.GET_REPORT_PDF });

  try {
    // const queryString = stringifyFilters(filters);
    const response = await axios.get(`${url}/${id}/generate_pdf`, {
      responseType: 'blob',
    });

    const content = response.headers['content-type'];

    download(response.data, `relatorio_${id}.pdf`, content);

    dispatch({ type: actionTypes.GET_REPORT_PDF_SUCCESS });

    // dispatch({
    //     type: actionTypes.GET_REPORT_SUCCESS,
    //     payload: { data }
    // });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_REPORT_PDF_ERROR,
      payload: { error: 'erros.text35' },
    });
  }
};

// POST
export const createReport = (report, projectId, shadowTeamId, shadowTeamPositions) => async dispatch => {
  dispatch({ type: actionTypes.CREATE_REPORT });
  try {
    const postData = {
      name: '',
      description: '',
      project: null,
      shadow_team: null,
      shadow_team_positions: null,
      configuration: {
        overall_aspects: report.configuration?.generalFeatures,
        negotiation: report.configuration?.negotiation,
        opinion: report.configuration?.opinion,
        background: report.configuration?.background,
        images: report.configuration?.images,
        evaluations: report.configuration?.evaluations,
        aspects: report.configuration?.aspects,
        microaspects: report.configuration?.microaspects,
        performance: report.configuration?.historic,
        career: report.configuration?.career,
        lastShadowTeamSchema: report.configuration?.lastShadowTeamSchema,
        lastShadowTeamSchemaImage: report.configuration?.lastShadowTeamSchemaImage,
      },
      athletes: report.athletes.map(athlete => ({
        athlete: athlete.athlete,
        report_data: {
          overall_aspects: athlete.reportData.generalFeatures,
          negotiation: athlete.reportData.negotiation,
          opinion: athlete.reportData.opinion,
          evaluationId: athlete.reportData.evaluationId,
          microaspects: athlete.reportData.microaspects,
          background: athlete.reportData.background,
          assigns: athlete.reportData.assigns,
        },
      })),
    };
    if (projectId) {
      postData.project = projectId;
      if (shadowTeamId) {
        postData.shadow_team = shadowTeamId;
      }
      if (shadowTeamPositions) {
        postData.shadow_team_positions = shadowTeamPositions;
      }
    }
    const createResponse = await axios.post(Endpoints.reports, postData);

    const getResponse = await _getReport(createResponse.data.id);
    dispatch({ type: actionTypes.CREATE_REPORT_SUCCESS, payload: { data: getResponse } });
  } catch (err) {
    dispatch({
      type: actionTypes.CREATE_REPORT_ERROR,
      payload: { error: 'erros.text36' },
    });
  }
};

// PUT
// PUT
export const updateReportConfiguration = async (reportId, reportConfiguration) => {
  const { data } = await axios.put(`${Endpoints.reports}/${reportId}`, {
    configuration: reportConfiguration,
  });
  return data;
};

export const updateReport = (createdReport, report) => async dispatch => {
  dispatch({ type: actionTypes.UPDATE_REPORT });

  delete createdReport.shadow_team_athletes;
  delete createdReport.shadow_team_positions_selected;
  let positionsSelected;
  if (report.shadow_team_positions_selected) {
    positionsSelected = report.shadow_team_positions_selected.join(',');
  }

  const url = Endpoints.reports;

  try {
    const updatedReport = {
      ...createdReport,
      name: report.name,
      description: report.description,
      shadow_team_positions: positionsSelected,
      configuration: {
        overall_aspects: report.configuration?.generalFeatures,
        negotiation: report.configuration?.negotiation,
        opinion: report.configuration?.opinion,
        background: report.configuration?.background,
        images: report.configuration?.images,
        evaluations: report.configuration?.evaluations,
        aspects: report.configuration?.aspects,
        microaspects: report.configuration?.microaspects,
        performance: report.configuration?.historic,
        career: report.configuration?.career,
        lastShadowTeamSchema: report.configuration?.lastShadowTeamSchema,
        lastShadowTeamSchemaImage: report.configuration?.lastShadowTeamSchemaImage,
      },
      athletes: report.athletes.map(athlete => ({
        athlete: athlete.athlete,
        report_data: {
          overall_aspects: athlete.reportData.generalFeatures,
          negotiation: athlete.reportData.negotiation,
          opinion: athlete.reportData.opinion,
          evaluationId: athlete.reportData.evaluationId,
          microaspects: athlete.reportData.microaspects,
          background: athlete.reportData.background,
          files: athlete.reportData.files,
        },
      })),
    };

    await axios.put(`${url}/${createdReport.id}`, updatedReport);
    if (report.athletes.length !== createdReport.athletes.length) {
      await dispatch(getReport(createdReport.id, false));
    }

    dispatch({ type: actionTypes.UPDATE_REPORT_SUCCESS });
  } catch (err) {
    dispatch({
      type: actionTypes.UPDATE_REPORT_ERROR,
      payload: { error: 'erros.text37' },
    });
  }
};

export const deleteReports = (reports, filters) => async dispatch => {
  dispatch({ type: actionTypes.DELETE_REPORTS });
  const url = Endpoints.reports;

  try {
    await Promise.all(reports.map(report => axios.delete(`${url}/${report}`)));

    await dispatch({
      type: actionTypes.DELETE_REPORTS_SUCCESS,
    });

    dispatch(getReports(filters));
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_REPORTS_ERROR,
      payload: { error: 'erros.text38' },
    });
  }
};
