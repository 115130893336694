import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import NavSection from './NavSection/NavSection';
import styles from './NavDrawer.module.scss';
import { Avatar, Backdrop } from 'components/template';
import AgentsLogo from 'assets/ico/menu/agents.svg';
import ClubsLogo from 'assets/ico/menu/clubs.svg';
import FeedLogo from 'assets/ico/menu/feed.svg';
import DashboardLogo from 'assets/ico/menu/dashboard.svg';
import TransferMarketLogo from 'assets/ico/menu/transfermarket.svg';
import SearchLogo from 'assets/ico/menu/search.svg';
import CompetitionLogo from 'assets/ico/menu/competition.svg';
import MarketAnalysisLogo from 'assets/ico/menu/marketanalysis.svg';
import { useTranslation } from 'react-i18next';

function NavDrawer({ history, isOpen, hide }) {
  const navDrawerClasses = [styles.navDrawer];
  const user = useSelector(state => state.user.data);
  const notifications = useSelector(state => state.notifications);
  const hasTeam = !!user?.organization?.teamId;
  const { t } = useTranslation();

  if (isOpen) navDrawerClasses.push(styles.open);

  const body = document.querySelector('body');
  if (isOpen) {
    body.classList.add('overflowHidden');
  } else {
    body.classList.remove('overflowHidden');
  }

  const newTransferMarketMessages = notifications?.data?.newTransferMarketMessages ?? false;
  const userType = user?.organization?.id?.type;
  return (
    <>
      <Backdrop style={{ top: '60px' }} onClick={hide} isVisible={isOpen} />

      <div className={navDrawerClasses.join(' ')}>
        <Scrollbar style={{ height: 'calc(100vh - 30px)' }}>
          <div className={styles.profileSection} style={{ height: user.organization != null ? '250px' : '160px' }}>
            <div className={styles.profileCover}></div>
            <div className={styles.profilePicture}>
              <Avatar className={styles.avatar} img={user.img} name={user.name} />
            </div>
            <div className={styles.profileInfo}>
              <span className={user.plan.has_any_permission ? '' : styles.centeredName}>{user.name}</span>
              {user.plan.has_any_permission ? <a href="/perfil">{t('nav_drawer.meuPerfil')}</a> : <br />}
            </div>
            {user.organization != null && (
              <div className={styles.profileClub}>
                <span className={styles.clubSpan}>{t('nav_drawer.organizacao')}</span>
                <div className={styles.orgContainer}>
                  <div className={styles.org_section}>
                    {user.organization?.type !== 'team' && (
                      <div className={styles.org_section_inner_container}>
                        <Avatar img={user.organization?.id.emblem} name={user.organization?.id.nickname} />
                        <div className={styles.org_section_texts}>
                          <span>{user.organization?.id?.nickname ?? user.organization?.shortName}</span>
                          {user.plan.agencias && (
                            <div
                              onClick={() => {
                                hide();
                                history.push('/minha-agencia');
                              }}
                            >
                              {t('nav_drawer.verPerfilMinhaAgencia')}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {hasTeam && (
                      <div className={styles.org_section_inner_container}>
                        <Avatar img={user.organization?.img} name={user.organization?.shortName} />
                        <div className={styles.org_section_texts}>
                          <span>{user.organization?.id?.nickname ?? user.organization?.shortName}</span>
                          {user.plan.meu_clube && (
                            <div
                              onClick={() => {
                                hide();
                                history.push(`/meu-clube`);
                              }}
                            >
                              {t('nav_drawer.verPerfilMeuClube')}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.nav_section_container}>
            <div className={styles.moduleSpan}>Módulos</div>
            {user?.is_super_user &&
              <NavSection
                hideNavDrawer={hide}
                title={t('nav_drawer.dashboard')}
                icon={<img draggable="false" src={DashboardLogo} alt={t('nav_drawer.dashboard')} />}
                iconColor="iconPurple"
                to="/dashboard"
              />
            }
            <NavSection
              hideNavDrawer={hide}
              title={t('nav_drawer.feed')}
              icon={<img draggable="false" src={FeedLogo} alt={t('nav_drawer.feed')} />}
              iconColor="iconPurple"
              to="/home"
            />
            <NavSection
              hideNavDrawer={hide}
              title={t('nav_drawer.buscaAtletas')}
              icon={<img draggable="false" src={SearchLogo} alt={t('nav_drawer.buscaAtletas')} />}
              iconColor="iconPink"
              to="/busca-avancada"
              paymentRequired={!user.plan.busca_de_atletas}
            />
            <NavSection
              hideNavDrawer={hide}
              title={t('nav_drawer.clubes')}
              icon={<img draggable="false" src={ClubsLogo} alt={t('nav_drawer.clubes')} />}
              iconColor="iconGreen"
              to="/clubes-brasileiros/serie-a"
              paymentRequired={!user.plan.clubes}
            />
            <NavSection
              hideNavDrawer={hide}
              title={t('nav_drawer.competicoesBase')}
              icon={<img draggable="false" src={CompetitionLogo} alt={t('nav_drawer.competicoesBase')} />}
              iconColor="iconBlue"
              to="/base/competicoes"
              paymentRequired={!user.plan.competicoes_de_base}
            />
            <NavSection
              hideNavDrawer={hide}
              title={t('nav_drawer.analiseMercado')}
              icon={<img draggable="false" src={MarketAnalysisLogo} alt={t('nav_drawer.analiseMercado')} />}
              iconColor="iconBrown"
              to={user.plan.projects_limit !== 0 ? '/projetos' : '/projetos/relatorios'}
              paymentRequired={!user.plan.analise_de_mercado}
            />
            <div>
              {newTransferMarketMessages && <div className={styles.menuNotification} />}
              <NavSection
                paymentRequired={!user.plan.mercado_de_transferencias}
                hideNavDrawer={hide}
                title={t('nav_drawer.mercadoTransferencias')}
                icon={
                  <img draggable="false" src={TransferMarketLogo} alt={t('nav_drawer.mercadoTransferencias')} />
                }
                iconColor="iconYellow"
                to={userType === 'agency' ? "/transfer-market/my-ads" : "/transfer-market"}
              />
            </div>
            <NavSection
              hideNavDrawer={hide}
              title={t('nav_drawer.agencias')}
              icon={<img draggable="false" src={AgentsLogo} alt={t('nav_drawer.agencias')} />}
              iconColor="iconGray"
              to="/agencias"
              paymentRequired={!user.plan.agencias}
            />
            <div style={{ height: '30px' }}></div>
          </div>
        </Scrollbar>
      </div>
    </>
  );
}

NavDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default withRouter(NavDrawer);
